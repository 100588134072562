import React, { useContext, useState } from "react";
import BoardFastFilters from "../../Board/BoardFastFilters/BoardFastFilters";
import "./UserMonitorToolbar.css"
import "../../../globalstyles/text.pc.css"
import BtnFilter from "../../buttons/btnFilter.component/BtnFilter";
import BlueStarIcon from "../../../assets/ButtonIcons/favourite-icon-blue.svg"
import BlueStarIconActive from "../../../assets/ButtonIcons/favourite-icon-blue-active.svg"

import DisplayOptionRowsIcon from "../../../assets/ButtonIcons/display-type-rows-icon-blue.svg"
import DisplayOptionCardsIcon from "../../../assets/ButtonIcons/display-type-cards-icon-blue.svg"
import FilterIcon from "../../../assets/TaskIcons/filterIcon.svg"

import Search from "../../Shared/Search/Search";
import { InputSwitch } from 'primereact/inputswitch';
import {IProjectGeneralInfo} from "../../../models/ProjectModels";
import MonitorFastFilter from "../FastFilters/MonitorFastFilter";

interface IUserMonitorToolbar {
    showOnlyFavourite: boolean
    showCompleted: boolean

    setShowOnlyFavourite: (value: boolean) => void;
    setShowCompleted: (value: boolean) => void;

    //Поиск
    taskNameSearch: string;
    setTaskNameSearch: (value: string) => void;
}

const UserMonitorToolbar: React.FC<IUserMonitorToolbar> = ({
                                               showOnlyFavourite,showCompleted,setShowOnlyFavourite, setShowCompleted, taskNameSearch, setTaskNameSearch
}) => {

    const [favouriteChecked, setFavouriteChecked] = useState<boolean>(false);

    const [displayOptionRows, setDisplayOptionRows] = useState<boolean>(true);

    function onSearchSubmit(){
        setTaskNameSearch(taskNameSearch)
    }

    function onShowCompletedChange(){
        setShowCompleted(!showCompleted);
    }

    return (
        <div className="user-monitor-toolbar">
            <div className="user-monitor-toolbar-left-part">
                <MonitorFastFilter/>
                <div className="user-monitor-toolbar-switch-panel">
                    <a className="a_header_name_16">Показать завершенные</a>
                    <InputSwitch className={showCompleted ? "show-completed-input-switch" : ""}
                                 checked={showCompleted} onChange={onShowCompletedChange}/>
                </div>
            </div>
            <div className="user-monitor-toolbar-right-part">
                <Search defaultValue={taskNameSearch} onSubmitFunc={onSearchSubmit} changeSearchValue={setTaskNameSearch}/>
                <div className="user-monitor-toolbar-button" onClick={e=> {setDisplayOptionRows(!displayOptionRows)}}>
                    {displayOptionRows ?
                        <img src={DisplayOptionRowsIcon} alt="Опции представления"/> :
                        <img src={DisplayOptionCardsIcon} alt="Опции представления"/>
                    }
                </div>
                <div className="user-monitor-toolbar-button" onClick={e=> {setShowOnlyFavourite(!showOnlyFavourite)}}>
                    {showOnlyFavourite ?
                        <img src={BlueStarIconActive} alt="Только избранные"/> :
                        <img src={BlueStarIcon} alt="Только избранные"/>
                    }
                </div>
                <div className="user-monitor-toolbar-button">
                    <img src={FilterIcon} alt="Фильтры"/>
                </div>
            </div>
        </div>
    );
};

export default UserMonitorToolbar;
