import React, { FC, useContext, useEffect, useRef } from 'react';
import { observer } from "mobx-react-lite";
import AddElement from "../../../assets/addElement.svg";
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import {AppContext} from "../../../App";


export interface IAddButtonProps {
    onClickFunc: (e: any) => void;
    type: string;
    permission: boolean | undefined;
}

const BtnAdd: FC<IAddButtonProps> = ({ onClickFunc, type,permission }) => {
    const { showToast } = useContext(AppContext);

    if (type == 'file') {
        return (
            <div>
                <label className="input-file margin_btn">
                    {permission ?
                        <input onChange={(e) => (onClickFunc(e))} type="file"/>
                    : null}
                    <span onClick={() => (permission ? {} : showToast("У вас нет прав для добавления файла!"))} className="input-file-btn" style={permission ? {} : {backgroundColor: "#75848a49"}}><img src={AddElement} /></span>
                </label>
            </div>
        )
    } else {
        return (
            <div key={type} className="button_add margin_btn" style={permission ? {} : {backgroundColor: "#75848a49"}} onClick={onClickFunc}>
                <img src={AddElement} />
            </div>
        )
    }


}

export default observer(BtnAdd);



