import React, { useContext, useEffect, useState } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import "../HeadNamePage/HeadNamePage.css";
import { AppContext } from "../../../App";
import { IBreadCrumb } from "../../../models/IBreadCrumb";
import { MenuItem } from "primereact/menuitem";
import { Link } from "react-router-dom";

export default function HeadNamePage() {
    const { breadCrumb } = useContext(AppContext);

    const [breadCrumbs, setBreadCrumbs] = useState<IBreadCrumb[]>([]);

    useEffect(() => {
        setBreadCrumbs(
            breadCrumb.map((b: IBreadCrumb) => {
                const bread: MenuItem = {
                    label: b.label,
                    url: b.url,
                    template: <Link to={b.url}>{b.label}</Link>,
                };
                return bread;
            })
        );
    }, [breadCrumb]);

    return (
        <div className="headName">
            <BreadCrumb model={breadCrumbs} />
        </div>
    );
}
