import { FC, useContext, useEffect, useState } from "react";
import { Context } from "../../index";
import "../../globalstyles/page.pc.css";
import '../Projects/Projects.css'
import { observer } from "mobx-react-lite";
import "../../pages/Project/Project.css";
import BtnAdd from "../../component/buttons/btnAdd.component/BtnAdd";
import AddEditProject from "../../component/Shared/AddEditProject/AddEditProject";
import { ICardProject } from "../../models/IProject";
import CopyTextButton from "../../component/buttons/CopyTextButton/CopyTextButton";
import { ProgressBar } from "primereact/progressbar";

import ProjectLogo from '../../assets/default__background.jpg';
import BoardFrame from '../../assets/board_frame.svg';
import ProjecFrameGray from '../../assets/project_frame_gray.svg';
import ProjectFrame from '../../assets/project_frame.svg';
import ProjectFrameNone from '../../assets/projects_none.svg';
import { useNavigate } from "react-router-dom";
import Avatar from "../../component/Shared/Avatar/Avatar";

// import { Avatar } from 'primereact/avatar';
import { AvatarGroup } from 'primereact/avatargroup';
import Bacground from "../../assets/backgroundLogin.jpg";
import { API_URL } from "../../http";
import { AppContext } from "../../App";
// import { Badge } from 'primereact/badge';

const Projects: FC = () => {
    const { store } = useContext(Context);

    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [hrefCoverCompany, setHrefCoverCompany] = useState<string | undefined>("");
    const { setNavPanelHighlight, setBreadCrumb } = useContext(AppContext);
    const [projects, setProjects] = useState<ICardProject[]>([]);
    const navigate = useNavigate();

    const loadCover = async () => {
        try {
            setNavPanelHighlight("projects");
            setBreadCrumb([{label: "Проекты", url: "/projects"}])
            const picture = await store.getSystemCover();
            setHrefCoverCompany(picture?.length > 0 ? picture : ProjectLogo)
        } catch (err) {
            setHrefCoverCompany(ProjectLogo)
            console.log(err)
        }
    };

    useEffect(() => {
        (async () => {
            try {
                await loadData();
            } catch (error) {
                console.log(error)
            }
        })();
    }, []);

    const loadData = async () => {
        await store.getAllProject();
        store.projects &&  setProjects(store.projects)
        await loadCover();
    }

    const handlClick = (projectId: number) => {
        navigate(`/project/${projectId}`)
    }

    function formatDate(dateStr: any): string {
        try {
            let date = new Date(dateStr);
            const padWithZero = (value: number) => value.toString().padStart(2, '0');
            const day = padWithZero(date.getDate());
            const month = padWithZero(date.getMonth() + 1); // Months are zero-indexed
            const year = date.getFullYear();
    
            return `${day}.${month}.${year}`;
            // return dateStr
        } catch (err: any) {
            return "-"
        }
    }

    return (
        <div>
            <div style={{ marginLeft: "10px" }}>
                <BtnAdd
                    onClickFunc={() => {
                        setOpenEdit(true);
                    }}
                    type=""
                    permission={true}
                />
            </div>
            {openEdit && <AddEditProject setOpen={setOpenEdit} onAction={loadData}/>}

            <div className="projects_main_container">
                {projects?.length ? projects.map((i: ICardProject) =>
                (
                    <div onClick={() => handlClick(i.id)} key={i.id} className="card_projects_container">

                        <div className="card_projects_img_wrap">
                            <div className="card_projects_boards_info">
                                <div className="flex ">
                                    <div className="p_blue p_14 text_margin">{i.boardsNum}</div>
                                    <img src={BoardFrame} alt="" />
                                </div>
                                <div className="flex">
                                    <div className="p_14 text_margin">{i.projectsNum}</div>
                                    <img src={ProjectFrame} alt="" />
                                </div>
                            </div>
                            <div className="card_projects_dates_period">
                                {i.dateTo ? formatDate(i.dateFrom) + "-" + formatDate(i.dateTo) : formatDate(i.dateFrom)}
                            </div>
                            <div className="card_projects_img">
                                <img src={i.img ? `${API_URL}/Project/getFile?fileId=${i.img}` : hrefCoverCompany} alt="" />
                            </div>
                        </div>

                        <div className="card_projects_info">
                            <div className="text_Card_name_container">
                                <h1 className="text_Card_name">{i.shortDescription}</h1>
                            </div>

                            <div className="card_projects_code">
                                <div className="text_Card_code">{i.code}</div>
                                {/* <CopyTextButton textDivId={i.code} /> */}
                            </div>
                            <div className="text_Card_description_container">
                                <div className="text_Card_description">{i.description}</div>
                            </div>
                            <div className="card_projects_footer">
                                <div className="card_projects_users">
                                    {i.users?.length !== 0 && i.users.map((user, index) => (
                                        index < 4 &&
                                        (<div className="avatar_group_item">
                                            <Avatar size="s" userName={user.name} />
                                        </div>)
                                    ))}
                                    {i.users?.length > 4 &&
                                        (
                                            <div className="count_users">
                                                <div className="p_gray p_19 ">
                                                    +{i.users?.length - 4}
                                                </div>
                                            </div>
                                        )}



                                </div>
                                <div className="card_projects_boards_info_footer">
                                    <div className="flex ">
                                        <div className="p_blue p_14 text_margin">{i.boardsNum}</div>
                                        <img src={ BoardFrame} alt="" />
                                    </div>
                                    <div className="flex">
                                        <div className="p_14 text_margin p_gray">{i.projectsNum}</div>
                                        <img src={ProjecFrameGray} alt="" />
                                    </div>
                                </div>
                                <div className="card_projects_progress">
                                    <div className='card_projects_progress_item'>
                                        <p className='genInfo_body_item_p11 p_gray'>ПРОГРЕСС</p>
                                        <p className='genInfo_body_item_p11 p_blue'>{i.percentageCompletion}%</p>
                                    </div>

                                    <ProgressBar value={i.percentageCompletion ?? 0}></ProgressBar>

                                </div>
                            </div>
                        </div>

                    </div>
                )
                ) :
                    <div className="projects_none_container">
                        <img src={ProjectFrameNone} alt="" />
                        <h1>У вас пока нет ни одного проекта</h1>
                    </div>}
            </div>


        </div>
    );
};

export default observer(Projects);
