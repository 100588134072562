import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { observer } from "mobx-react-lite";
import { useParams } from 'react-router-dom';
import "../../styles/filesComponent.pc.css";
import { Context } from "../../../index";
import '../../Project/Files/Files.css';
import './TaskFiles.css';
import {
    IFileFilterOptions,
    IFileFilters,
    IFilesDisplay,
    IFileTaskFilters,
    IGetFilesTaskDisplayData
} from "../../../models/FileModels";
import BtnDownload from "../../buttons/btnDownload.component/BtnDownload";
import FileImage from "../../../assets/fileImage.svg"
import FileVideo from  "../../../assets/fileVideo.svg"
import FileDocument from "../../../assets/fileDocument.svg"
import BtnAdd from "../../buttons/btnAdd.component/BtnAdd";
import TaskBtnAdd from "../TaskBtnAdd/TaskBtnAdd";

//Для подгрузки иконок файлов (Что-то страшное получилось, но не понятно как много svg за раз грузить)
//Чтобы добавть новую иконку, нужно:
// 1. загрузить svg в "../../../assets/FileIcons/xsl.svg"
// 2. Добавить импорт ниже
// 3. Добавить переменную из импорта в fileIcons
import unknown_svg from "../../../assets/FileIcons/unknown.svg"
import xsl_svg from "../../../assets/FileIcons/xsl.svg"
import avi_svg from "../../../assets/FileIcons/avi.svg"
import bmp_svg from "../../../assets/FileIcons/bmp.svg"
import crd_svg from "../../../assets/FileIcons/crd.svg"
import csv_svg from "../../../assets/FileIcons/csv.svg"
import dll_svg from "../../../assets/FileIcons/dll.svg"
import doc_svg from "../../../assets/FileIcons/doc.svg"
import docx_svg from "../../../assets/FileIcons/docx.svg"
import dwg_svg from "../../../assets/FileIcons/dwg.svg"
import eps_svg from "../../../assets/FileIcons/eps.svg"
import exe_svg from "../../../assets/FileIcons/exe.svg"
import flv_svg from "../../../assets/FileIcons/flv.svg"
import gif_svg from "../../../assets/FileIcons/gif.svg"
import html_svg from "../../../assets/FileIcons/html.svg"
import iso_svg from "../../../assets/FileIcons/iso.svg"
import java_svg from "../../../assets/FileIcons/java.svg"
import jpg_svg from "../../../assets/FileIcons/jpg.svg"
import mdb_svg from "../../../assets/FileIcons/mdb.svg"
import mid_svg from "../../../assets/FileIcons/mid.svg"
import mov_svg from "../../../assets/FileIcons/mov.svg"
import mp3_svg from "../../../assets/FileIcons/mp3.svg"
import mpeg_svg from "../../../assets/FileIcons/mpeg.svg"
import pdf_svg from "../../../assets/FileIcons/pdf.svg"
import png_svg from "../../../assets/FileIcons/png.svg"
import ppt_svg from "../../../assets/FileIcons/ppt.svg"
import ps_svg from "../../../assets/FileIcons/ps.svg"
import psd_svg from "../../../assets/FileIcons/psd.svg"
import pub_svg from "../../../assets/FileIcons/pub.svg"
import rar_svg from "../../../assets/FileIcons/rar.svg"
import raw_svg from "../../../assets/FileIcons/raw.svg"
import rss_svg from "../../../assets/FileIcons/rss.svg"
import svg_svg from "../../../assets/FileIcons/svg.svg"
import tiff_svg from "../../../assets/FileIcons/tiff.svg"
import txt_svg from "../../../assets/FileIcons/txt.svg"
import wav_svg from "../../../assets/FileIcons/wav.svg"
import wma_svg from "../../../assets/FileIcons/wma.svg"
import xml_svg from "../../../assets/FileIcons/xml.svg"
import zip_svg from "../../../assets/FileIcons/zip.svg"

const fileIcons = {xsl_svg, avi_svg, bmp_svg, crd_svg, csv_svg, dll_svg, doc_svg, docx_svg, dwg_svg, eps_svg, exe_svg, flv_svg, gif_svg, html_svg, iso_svg, java_svg, jpg_svg, mdb_svg, mid_svg, mov_svg, mp3_svg, mpeg_svg, pdf_svg, png_svg, ppt_svg, ps_svg, psd_svg, pub_svg, rar_svg, raw_svg, rss_svg, svg_svg, tiff_svg, txt_svg, wav_svg, wma_svg, xml_svg, zip_svg}

const TaskFiles: FC = () => {
    const { store } = useContext(Context);
    const { id } = useParams();
    const [deletFilesIds, setDeletFilesIds] = useState<number[]>([]);
    const [files, setFiles] = useState<IFilesDisplay | undefined>(undefined);
    const [filesFilterOptions, setFilesFilterOptions] = useState<IFileFilterOptions | undefined>(undefined);
    const filters = useRef<IFileFilters>({
        projectId: 1,
        fileType: [],
        name: ''
    })

    const loadFiles = async (filters: IFileTaskFilters) => {
        let req: IGetFilesTaskDisplayData = {
            filters: filters
        }
        const res = await store.getFilesTaskDisplayData(req) as IFilesDisplay;
        setFiles(res);
    };

    function onDownloadClick(itemId: number, type: string, name: string) {
        (async () => {
            try {

            } catch (error) {
                console.error('Download error:', error);
            }
        })();
    }

    function onDeleteClick() {
        deletFilesIds?.length && (async () => {
            await store.deleteFiles(deletFilesIds);
            await loadFiles({
                taskId: Number(id),
                name: '',
                fileType: []
            })
        })();
    };

    function onAddClick(e: any) {
        (async () => {
            await store.addFileChat(e.target.files[0], Number(id), 1);
            console.log(e.target.files[0])
            await loadFiles({
                taskId: Number(id),
                name: '',
                fileType: []
            })
        })();
    };

    const handleFileSelection = (fileId: number, event: any) => {
        let ids = deletFilesIds;
        if (event.target.checked) {
            ids.push(fileId);
        } else {
            let index = ids.findIndex(i => i === fileId);
            if (index !== -1) {
                ids.splice(index, 1);
            }
        }
        setDeletFilesIds(ids);
    };

    useEffect(() => {
        (async () => {
            await loadFiles({
                taskId: Number(id),
                name: '',
                fileType: []
            })
        })();
    },[id, store.updateFile]);

    //Принимает на вход тип картинки и возвращает элемент с соответсвующей иконкой.
    function fileIcon(fileType: string | undefined){
        let fileTypeClean = fileType?.replace(/\./g, '')
        let variableName = `${fileTypeClean}_svg`
        let pictureSrc = (fileIcons as any)[variableName]
        if(!pictureSrc){
            //Тут если не нашли картинку нужна женерик картинка файла
            return (
                <img src={unknown_svg}/>
            )
        } else {
            return (
                <img src={pictureSrc}/>
            )
        }

    }

    return (
        <div className="task__component--right__menu">
            <div className="task__component--right__menu--files">
                <div className="task__component--right__menu--files__name">
                    Файлы - {files?.totalRecords}
                </div>
                <div className="task__component--right__menu--files__btn">
                    <TaskBtnAdd type={'file'} onClickFunc={onAddClick} />
                </div>
            </div>
            <div>
                <div id='files-widget-items-container'  className="files-widget-items-container__scroll">
                    {files?.files?.map((item, index) => (
                        <div key={item.id} className="files-widget-items-container__block">
                            <div className="files-widget-items-container__img">
                                {fileIcon(item.type)}
                            </div>
                            <div className='files-widget-file_item_name--text'>
                                <div title={`${item.name}${item.type}`} className='files-widget-file_item_name--text__curent'>{item.name.length+item.type.length > 15 ?
                                    item.name.slice(0,15)+"..." :
                                    item.name+item.type}</div>
                            </div>
                            <div style={{display: "flex", verticalAlign: "middle", marginLeft: "auto"}} className="task__component--right__menu--files__size">
                                <BtnDownload onClickFunc={onDownloadClick} itemId={item.id} url={item.url} type={item.type} name={item.name} permission={true}/>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    )
}

export default observer(TaskFiles);