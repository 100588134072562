import React, {FC, useContext, useEffect, useState} from 'react';
import {IExcludePermissionFunction} from "../../../models/IExcludePermissionFunction";
import {Context} from "../../../index";
import ArrowSub from "../../../assets/arrowDown.svg";
import ArrowRight from "../../../assets/ArrowRight.svg";
import "./ProjectEditRoleBoard.css"
import {ISaveExcludePermission} from "../../../models/ISaveExcludePermission";
import {all} from "axios";
import {IRestrictAccess, IRestrictAccessBoardsSave} from "../../../models/IRestrictAccess";
// import RestrictAccess from "../RestrictAccess/RestrictAccess";

interface Item {
    name: string;
    code: string;
}

interface IProjectEditRoleBoard {
    personId: Item | null | undefined
    closeFunc: () => void;
    roleEdit?: IExcludePermissionFunction | undefined
    projectId: number | null;
}

enum IPermissionCode {
    "create" = "Создание",
    "view" = "Просмотр",
    "delete" = "Удаление",
    "edit" = "Редактирование",
    "full" = "Все"
}

enum IEditPermissionCode {
    "create" = "create",
    "view" = "view",
    "delete" = "delete",
    "edit" = "edit",
    "full" = "full"
}

const ProjectEditRoleBoard: FC<IProjectEditRoleBoard> = ({personId, closeFunc, roleEdit,projectId}) => {
    const {store} = useContext(Context);
    const [userRole, setUserRole] = useState<IExcludePermissionFunction | undefined>();
    const [page, setPage] = useState<boolean>(true);
    const roleIdG = roleEdit?.roles.length! - 1;
    const [boardsData, setBoardsData] = useState<IRestrictAccess>();


    function onCheckEvent(id: number) {
        let boardIndex = boardsData?.boards.findIndex(board => board.id === id)!;
        let chekIn = boardsData?.boards[boardIndex].personRoleIds?.includes(userRole!.roles[roleIdG].personRoleId);
        if (chekIn) {
            let data = boardsData?.boards.map(xx => {
                if (xx.id == id) {
                    xx.personRoleIds = xx.personRoleIds?.filter(yy => yy != userRole!.roles[roleIdG].personRoleId)
                }
                return xx;
            })
            let newData: IRestrictAccess = JSON.parse(JSON.stringify(boardsData));
            newData.boards = data!;
            setBoardsData(newData);
            console.log(newData)
        } else {
            let newData: IRestrictAccess = JSON.parse(JSON.stringify(boardsData));
            if (newData.boards.find(xx => xx.id == id)?.personRoleIds == undefined) {
                newData.boards[newData.boards.findIndex(board => board.id === id)].personRoleIds = [];

                newData.boards[newData.boards.findIndex(board => board.id === id)].personRoleIds!.push(userRole!.roles[roleIdG].personRoleId);
            } else {
                newData.boards[newData.boards.findIndex(board => board.id === id)].personRoleIds!.push(userRole!.roles[roleIdG].personRoleId);
            }
            setBoardsData(newData)
            console.log(newData)
        }
    }

    function handleViewBlocks(arrowOne: string, arrowTwo: string, block: string) {
        const arrowOneFind = document.getElementById(arrowOne);
        const arrowTwoFind = document.getElementById(arrowTwo);
        const blockFind = document.getElementById(block);

        if (arrowOneFind && arrowTwoFind && blockFind) {
            if (arrowOneFind.style.display === 'none') {
                arrowOneFind.style.display = 'block';
            } else {
                arrowOneFind.style.display = 'none';
            }
            if (arrowTwoFind.style.display === 'none') {
                arrowTwoFind.style.display = 'block';
            } else {
                arrowTwoFind.style.display = 'none';
            }
            if (blockFind.style.display === 'none') {
                blockFind.style.display = 'block';
            } else {
                blockFind.style.display = 'none';
            }
        }
    }

    function saveExclude() {
        let allChoisePerm = userRole!.roles[roleIdG].excludedPermissions.map(xx => xx.accessPermission);
        let allChoisePermSystem = userRole!.roles[roleIdG].functionGroups.flatMap(xx => 
            xx.systemFunctions.flatMap(yy => 
                yy.accessPermissions.map(zz => zz.id)
            )
        );

        const data: ISaveExcludePermission = {
            personRoleId: userRole!.roles[roleIdG].personRoleId,
            accessPermissionIds: allChoisePerm
        };

        let checkBoard = boardsData?.boards.map((xx) =>
            xx).filter(x =>
            x.personRoleIds?.includes(userRole!.roles[roleIdG].personRoleId)).map(xx => xx.id);
        let restrict: IRestrictAccessBoardsSave[] = [];

        if (checkBoard?.length! > 0) {
            let findUser = boardsData?.person.find(xx => xx.personRoleId == userRole!.roles[roleIdG].personRoleId);
            checkBoard?.forEach((xx) => {
                restrict.push({
                    personRoleId: findUser!.personRoleId,
                    allColumns: true,  //TODO нужно доделать логику колонок
                    boardId: xx,
                    columnIds: undefined  //TODO нужно доделать логику колонок
                })
            })
        }

        console.log(restrict);

        (async () => {
            try {
                const boardSave = await store.saveRestrict(restrict);
                const res = await store.saveExcludeAccessPermission(data);
                closeFunc();
            } catch (err) {
                console.log(err)
            }
        })();
    }

    function changeGroup(groupId: number, checked: boolean) {
        let getData: IExcludePermissionFunction | undefined = JSON.parse(
            JSON.stringify(userRole)
        );

        if (getData && getData.roles[roleIdG]) {
            let currentGroup = getData.roles[roleIdG].functionGroups.find((xx) => xx.id == groupId);
            let allSystemFunction = currentGroup!.systemFunctions.flatMap(xx => xx.accessPermissions);
            let allExclude = getData.roles[roleIdG].excludedPermissions.map(xx => xx.accessPermission);

            if (checked == false) {
                allSystemFunction.forEach((xx) => {
                    if (!allExclude.includes(xx!.id)) {
                        getData!.roles[roleIdG].excludedPermissions.push({
                            id: 0,
                            accessPermission: xx!.id,
                            // personRoleId: 13
                            personRoleId: Number(personId!.code!)
                        });
                    }
                })
            } else {
                let newAccess = allSystemFunction.map(xx => xx!.id);
                getData.roles[roleIdG].excludedPermissions =
                    getData!.roles[roleIdG].excludedPermissions.filter(xx => !newAccess.includes(xx.accessPermission));
            }
        }
        setUserRole(getData);
    }

    function changeSystemFunctions(systemFunction: number, checked: boolean) {
        let getData: IExcludePermissionFunction | undefined = JSON.parse(
            JSON.stringify(userRole)
        );

        if (getData && getData.roles[roleIdG]) {
            let group = getData.roles[roleIdG].functionGroups.map((xx) => xx);
            let system = group.flatMap(xx => {
                const foundSystemFunction = xx.systemFunctions.find((xx) => xx.id === systemFunction);
                return foundSystemFunction ? foundSystemFunction : undefined;
            });
            let allAccessItem = system
                .flatMap(xx => xx?.accessPermissions)
                .filter(accessPermission => accessPermission !== undefined);
            let allExclude = getData.roles[roleIdG].excludedPermissions.map(xx => xx.accessPermission);
            if (checked == false) {
                allAccessItem.forEach((xx) => {
                    if (!allExclude.includes(xx!.id)) {
                        getData!.roles[roleIdG].excludedPermissions.push({
                            id: 0,
                            accessPermission: xx!.id,
                            // personRoleId: 13
                            personRoleId: Number(personId!.code!)
                        });
                    }
                })
            } else {
                let newAccess = allAccessItem.map(xx => xx!.id);
                getData.roles[roleIdG].excludedPermissions =
                    getData!.roles[roleIdG].excludedPermissions.filter(xx => !newAccess.includes(xx.accessPermission));
            }
        }
        setUserRole(getData);
    }

    function changeTab() {
        setPage(!page);
    }

    function chengeAccessPermissions(accessPermission: number) {
        let getData: IExcludePermissionFunction | undefined = JSON.parse(
            JSON.stringify(userRole)
        );
        if (getData && getData.roles[roleIdG]) {
            let allAccessItem = getData.roles[roleIdG].excludedPermissions.map((xx) => xx.accessPermission);
            let findItem = allAccessItem.find((xx) => xx === accessPermission);

            if (findItem) {
                getData.roles[roleIdG].excludedPermissions =
                    getData.roles[roleIdG].excludedPermissions.filter(
                        (xx) => xx.accessPermission !== accessPermission
                    );
            } else {
                getData.roles[roleIdG].excludedPermissions.push({
                    id: 0,
                    accessPermission: accessPermission,
                    // personRoleId: 13
                    personRoleId: Number(personId!.code!)
                });
            }


        }
        console.log(getData?.roles[roleIdG].excludedPermissions);
        setUserRole(getData);
    }

    useEffect(() => {
        (async () => {
            try {
                const data = roleEdit;
                setUserRole(data);
                if (projectId) {
                    const boardData = await store.restrictAccess(projectId)
                    setBoardsData(boardData);
                    console.log(boardData)
                } else {
                    console.log("Не найден id проекта!")
                }
            } catch (err) {
                console.log(err)
            }
        })();
    }, [roleEdit]);

    return (
        <div className="project__edit--role__board--component">
            <div className="project__edit--role__board--component__in">
                <div className="project__edit--role__board--component__tab">
                    <div className={`project__edit--role__board--component__head ${page ? "project__edit--role__board--component__head--choise" : ""}`} onClick={changeTab}>
                        Права участника
                    </div>
                    <div className={`project__edit--role__board--component__head ${page ? "" : "project__edit--role__board--component__head--choise"}`} onClick={changeTab}>
                        Доступ к канбан доскам
                    </div>
                </div>
                {page ?
                    <div>
                        <div className="project__edit--role__board--component__edit">
                            <div>
                                <div className="project__edit--role__board--component__role">
                                    {userRole?.roles[roleIdG].roleName}
                                </div>
                                <div className="project__edit--role__board--component__block">
                                    {userRole?.roles[roleIdG].functionGroups.map((group) => (
                                        <div>
                                            <div className="project__edit--role__board--access__permissions">
                                                <div className="project__edit--role__board--arrow"
                                                     onClick={() => (handleViewBlocks("ShowGroupSub" + String(group.id),
                                                         "ShowGroupRight" + String(group.id), "ShowGroupBlock" + String(group.id)))}>
                                                    <img src={ArrowSub} id={"ShowGroupSub" + String(group.id)}
                                                         style={{display: 'none'}}/>
                                                    <img src={ArrowRight} id={"ShowGroupRight" + String(group.id)}/>
                                                </div>
                                                <div>
                                                    {group.name}
                                                </div>
                                                <div
                                                    className="project__edit--role__board--access__permissions--input__margin">
                                                    <div
                                                        className="project__edit--role__board--access__permissions--input">
                                                        <input type="checkbox"
                                                               checked={group.systemFunctions.some(systemFunction =>
                                                                   systemFunction.accessPermissions.some(accessPermission =>
                                                                       !userRole?.roles[roleIdG].excludedPermissions.map(xx => xx.accessPermission).includes(accessPermission.id)
                                                                   ))}
                                                               onClick={() => (changeGroup(group.id, group.systemFunctions.some(systemFunction =>
                                                                   systemFunction.accessPermissions.some(accessPermission =>
                                                                       userRole?.roles[roleIdG].excludedPermissions.map(xx => xx.accessPermission).includes(accessPermission.id)
                                                                   ))))
                                                               }/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id={"ShowGroupBlock" + String(group.id)}
                                                 className="project__edit--role__board--access__permissions--block"
                                                 style={{display: 'none'}}>
                                                {group.systemFunctions.map((systemFunction) => (
                                                    systemFunction.accessPermissions.length > 0 ?
                                                        <div>
                                                            <div
                                                                className="project__edit--role__board--access__permissions">
                                                                <div className="project__edit--role__board--arrow"
                                                                     onClick={() => (handleViewBlocks("ShowAccessPermissionsSub" + String(systemFunction.id),
                                                                         "ShowAccessPermissionsRight" + String(systemFunction.id), "ShowAccessPermissionsBlock" + String(systemFunction.id)))}>
                                                                    <img src={ArrowSub}
                                                                         id={"ShowAccessPermissionsSub" + String(systemFunction.id)}
                                                                         style={{display: 'none'}}/>
                                                                    <img src={ArrowRight}
                                                                         id={"ShowAccessPermissionsRight" + String(systemFunction.id)}/>
                                                                </div>
                                                                <div
                                                                    className="project__edit--role__board--access__permissions--name__function">
                                                                    {systemFunction.nameFunction}
                                                                </div>
                                                                <div
                                                                    className="project__edit--role__board--access__permissions--input__margin">
                                                                    <div
                                                                        className="project__edit--role__board--access__permissions--input">
                                                                        <input type="checkbox"
                                                                               checked={systemFunction.accessPermissions.some(accessPermission =>
                                                                                   !userRole?.roles[roleIdG].excludedPermissions.map(xx => xx.accessPermission).includes(accessPermission.id)
                                                                               )}
                                                                               onClick={() => (changeSystemFunctions(systemFunction.id, systemFunction.accessPermissions.some(accessPermission =>
                                                                                   userRole?.roles[roleIdG].excludedPermissions.map(xx => xx.accessPermission).includes(accessPermission.id)
                                                                               )))}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                id={"ShowAccessPermissionsBlock" + String(systemFunction.id)}
                                                                className="project__edit--role__board--access__permissions--block__item"
                                                                style={{display: 'none'}}>
                                                                {systemFunction.accessPermissions.map((accessPermission) => (
                                                                    IPermissionCode[accessPermission.code as IEditPermissionCode] &&
                                                                    IEditPermissionCode[accessPermission.code as IEditPermissionCode] !==
                                                                    IEditPermissionCode.full ?
                                                                        <div
                                                                            className="project__edit--role__board--access__permissions--flex">
                                                                            <div
                                                                                className="project__edit--role__board--access__permissions--item">
                                                                                {IPermissionCode[accessPermission.code as IEditPermissionCode]}
                                                                            </div>
                                                                            <div
                                                                                className="project__edit--role__board--access__permissions--input__margin">
                                                                                <div
                                                                                    className="project__edit--role__board--access__permissions--input">
                                                                                    <input type="checkbox"
                                                                                           checked={!userRole?.roles[roleIdG].excludedPermissions.map(xx =>
                                                                                               xx.accessPermission).includes(accessPermission.id)}
                                                                                           onClick={() => (chengeAccessPermissions(accessPermission.id))}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        : null
                                                                ))}
                                                            </div>
                                                        </div>
                                                        : null
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="project__edit--role__board--component__edit">
                        <div className="restrict__access--list__board--head">
                            Канбан доски:
                        </div>
                        {boardsData?.boards.map((board) => (
                            <div className="restrict__access--list__board">
                                <div className="restrict__access--list__board--desc">
                                    {board.name}
                                </div>
                                <div className="restrict__access--list__board--chek">
                                    <div className="restrict__access--list__board--chek__size">
                                        <input type="checkbox" checked={board.personRoleIds?.includes(userRole!.roles[roleIdG].personRoleId) ? true: false} onClick={() => (onCheckEvent(board.id))} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                }
                <div className="project__edit--role__board__buttons">
                    <div className="project__edit--role__board__buttons--exit">
                        <button onClick={closeFunc}>
                            Отмена
                        </button>
                    </div>
                    <div className="project__edit--role__board__buttons--add">
                        <button onClick={saveExclude}>
                            Сохранить
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectEditRoleBoard;