import $api from "../http";
import {AxiosResponse} from "axios";

export default class UserMonitorService {
    //TODO убрать any
    static async getFavouriteTasks(): Promise<AxiosResponse<any>> {
        return $api.get<any>('/Monitor/favourite');
    }

    static async setFavouriteTasks(body: any): Promise<AxiosResponse<any>> {
        return $api.post<any>(`/Monitor/favourite`, body);
    }

    static async getTasks(body: any): Promise<AxiosResponse<any>> {
        return $api.post<any>(`/Monitor/getTasks`, body);
    }

    //TODO Нужен метод на получение вариантов для фильтрации.

}