import React, {useRef, useState} from "react";
import { observer } from "mobx-react-lite";
import "../../styles/filesComponent.pc.css";
import "../../styles/historyProject.pc.css";
import "../../../globalstyles/dataTableStyles.css";
import PersonLineDisplay from "../../Shared/PersonLineDisplay";
import { IProjectChangesDisplay } from "../../../models/ProjectModels";
import RightArrow from "../../../assets/rightArrow.svg";
import OpenBlock from "../../../assets/openBlock.svg";
import CloseBlockWindow from "../../../assets/arrowCloseWindow.svg";

const PAGE_SIZE = 5;

interface IHistoryProjectProps {
    change: IProjectChangesDisplay;
    scrollEnded: boolean;
    loadPage: (skip: number, take: number) => void;
}

function toDisplayDate(dateStr: any): string {
    try {
        let date = new Date(dateStr);
        const padWithZero = (value: number) =>
            value.toString().padStart(2, "0");
        const day = padWithZero(date.getDate());
        const month = padWithZero(date.getMonth() + 1);
        const year = date.getFullYear();

        //Перестанет работать в 2100 году :)
        return `${day}.${month}.${year - 2000}`;
    } catch (err: any) {
        return "-";
    }
}

const History: React.FC<IHistoryProjectProps> = ({change, scrollEnded, loadPage,}) => {
    const page = useRef<number>(0);
    const [openOnFullWindow, setOpenOnFullWindow] = useState(false);
    // Ограничение прав
    const [viewHistory, setViewHistory] = useState(false);



    const onScroll = (e: any) => {
        checkIfScrolledToBottom(e);
    };

    function checkIfScrolledToBottom(e: any) {
        const { scrollTop, offsetHeight, scrollHeight } = e.target;

        if (1 + scrollTop + offsetHeight >= scrollHeight && !scrollEnded) {
            page.current++;
            loadPage(0, page.current * PAGE_SIZE);
        }
    }

    function initials(str: string) {
        return str
            .split(/\s+/)
            .map((w, i) => (i ? w.substring(0, 1).toUpperCase() + "." : w))
            .join(" ");
    }

    return (
        <div id="main-history-widget" className={openOnFullWindow ? "widget_wrapper--full__window" : "widget_wrapper"}>
            <div className={openOnFullWindow ? 'widget_wrapper-full__window--block' : ''}>
                <div className="widget_header">
                    <div className="widjets_header_left">
                        {openOnFullWindow ?
                            <div onClick={() => setOpenOnFullWindow(false)} className='widgets_header--open__block'>
                                <img src={CloseBlockWindow}/>
                            </div>
                            :
                            <div onClick={() => setOpenOnFullWindow(true)} className='widgets_header--open__block'>
                                <img src={OpenBlock}/>
                            </div>
                        }
                        <h2 className="widget-card-header-style">
                            История проекта
                        </h2>
                    </div>
                </div>
                <div onScroll={onScroll} className="custom_table history_project_table ">
                    <table>
                        <thead>
                        <tr>
                            <th>ДАТА</th>
                            <th>ТИП ОБЪЕКТА</th>
                            <th>ТИП ИЗМЕНЕНИЯ</th>
                            <th>ОТВЕТСТВЕННЫЙ</th>
                            <th>ИЗМЕНЕНИЕ</th>

                        </tr>
                        </thead>
                        <tbody>
                            {viewHistory && change?.history?.map((record) => (
                                <tr key={record.id}>
                                    <td style={{width: "10%"}}>{toDisplayDate(record.date)}</td>
                                    <td style={{width: "12%"}}>
                                        {record.name === "task"
                                            ? "Задача"
                                            : record.name === "project"
                                                ? "Проект"
                                                : record.name === "board"
                                                    ? "Канбан-доска"
                                                    : ""}
                                    </td>
                                    <td style={{width: "15%"}}></td>
                                    <td style={{width: "20%"}}>
                                        <PersonLineDisplay
                                            name={initials(record.responsibleName)}
                                        />
                                    </td>
                                    <td style={{width: "55%"}}>
                                        {record.change && record.change.length ? (
                                            <div>
                                                {record.change[0].oldData}
                                                <img
                                                    src={RightArrow}
                                                    alt=""
                                                    height={10}
                                                />
                                                {record.change[0].newData}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default observer(History);
