import {useContext, createContext, useEffect, useState} from 'react';
import { observer } from "mobx-react-lite";
import "../styles/menu.pc.css";
import './LeftMenu.css'
import '../../globalstyles/ControlElementsStyles.css'
import { Context } from "../../index";
import Logo from '../../assets/logo.svg';
import {useNavigate} from "react-router-dom";
import NavigationPanel from '../NavigationMenu/NavigationPanel';
import { API_URL } from '../../http';


export const MenuFormContext = createContext<any>(null)
const MenuForm = () => {
    const { store } = useContext(Context);
    const navigate = useNavigate();
    const [isLoggedOut, setIsLoggedOut] = useState(false);
    const [hrefLogoCompany, setHrefLogoCompany] = useState<string | undefined>("");

    const handleLogout = async () => {
        try {
            const success: boolean | undefined = await store.logout();
            if (success) {
                setIsLoggedOut(true);
                navigate("/login")
            } else {
                console.error('Logout failed');
            }
        } catch (error) {
            console.error('Logout failed', error);
        }
    };

    function goToProject() {
        navigate('/projects');
    }

    useEffect(() => {
        (async () => {
            try {
                await store.getSystemSettings();
            } catch (error) {
                console.log(error)
            }
        })();
    }, []);

    useEffect(() => {
        const data = store.systemSettings;
        if (data) {
            let logo = data.find(xx => xx.paramName == "logo");
            if (logo) {
                setHrefLogoCompany(`${API_URL}/Project/getFile?fileId=${logo.fileId}`);
            } else {
                setHrefLogoCompany(Logo);
            }
        } else {
            setHrefLogoCompany(Logo);
        }
    }, [store.systemSettings]);

    return (
        <div className="menuForm__component">
            <div className="menuForm__component--head">
                <div className="company-logo-name">
                    <img src={hrefLogoCompany} onClick={goToProject}></img>
                </div>

                <div className="card flex justify-content-center">
                    <NavigationPanel />
                </div>
            </div>
            <div className='bottom-group'>
                <button onClick={() => handleLogout()}>
                    Выйти
                </button>
                <div>
                    <label>Показывать прокси-доски</label>
                    <input
                        type='checkbox'
                        checked={store.showProxyBoards}
                        onChange={(e) => {store.setShowProxyBoards(!store.showProxyBoards);}}/>
                </div>
                <button onClick={() => navigate("/feedback")}>
                    Обратная связь
                </button>
                <p>
                    <a className='wiki-link'>Руководство пользователя</a>
                </p>
            </div>
        </div>
    )

}


export default observer(MenuForm);