import React, { FC, useContext, useEffect } from 'react';
import { Context } from "../../../index";
import { observer } from "mobx-react-lite";
import "../../styles/avatar.pc.css";
import UserIcon from "../../../assets/avatar.svg";

enum ISize{
    xl = 'xl',
    lg = 'xl',
    m = 'm',
    s = 's'
}

interface IAvatar {
    url?: string;
    size?: string;
    userName?: string;
}
const Avatar: FC<IAvatar> = ({url , size, userName}) => {
    const { store } = useContext(Context);
    return (
        <div >
            {url ? <img className={`avatar' ${size}`} src={url} alt={userName} title={userName}/> :
            <img className={`avatar' ${size}`}  src={UserIcon} alt={userName} title={userName}/>}
            
        </div>
    )
}

export default observer(Avatar);