import $api from "../http";
import {AxiosResponse} from "axios";
import {AuthResponse} from "../models/response/AuthResponse";
import {IUser, ISustemPerson} from "../models/IUser";
import {IGetAllUsersWithSystemRoles} from "../models/IGetAllUsersWithSystemRoles";

export default class UserService {
    static fetchUsers(): Promise<AxiosResponse<IUser[]>> {
        return $api.get<IUser[]>('/getUser');
    }

    static getSystemPersonWithRoles(): Promise<AxiosResponse<ISustemPerson>> {
        return $api.get<ISustemPerson>('/getSystemPersonWithRoles');
    }

    static addRole(userId: number, roleId: number): Promise<AxiosResponse<IUser>> {
        return $api.post<IUser>('addRole', {userId, roleId});
    }

    static async getAllUsersWithSystemRoles(): Promise<AxiosResponse<IGetAllUsersWithSystemRoles[]>> {
        return $api.get<IGetAllUsersWithSystemRoles[]>('System/getSystemPersonWithRoles');
    }
}