import { createContext, FC, useContext, useEffect, useState } from "react";
import TaskInformationForm from "../../component/Task/TaskForm/TaskInformationForm";
import "./Task.css";
import TaskRightInfo from "../../component/Task/TaskRightInfo/TaskRightInfo";
import TaskChat from "../../component/Task/TaskChat/TaskChat";
import { AppContext } from "../../App";
import { Context } from "../..";
import { IBreadCrumb } from "../../models/IBreadCrumb";
import { Link, useParams } from "react-router-dom";
import { ISideBarBoard, ISideBarProject, ISideBarSubproject, TreeNode } from "../../models/LeftMenuModels";
import { ITaskGeneralInfo } from "../../models/ITask";
import { IGetAllTaskPerson } from "../../models/IAllTaskPerson";

interface TaskContextType {
    taskInformation: ITaskGeneralInfo | undefined;
    loadTaskInfo: () => void;
    getAllTaskPerson: () => void;
    allPerson: IGetAllTaskPerson | undefined;
}

export const TaskContext = createContext<TaskContextType>({
    taskInformation: undefined,
    loadTaskInfo: () => {},
    getAllTaskPerson: () => {},
    allPerson: undefined
});

const Task: FC = (index) => {
    const { setBreadCrumb, findBoard, sideBarProjects, setNavPanelHighlight } = useContext(AppContext);
    const { store } = useContext(Context);
    const [defaultBoardId, setDefaultBoardId] = useState<number>();
    const { boardId, id } = useParams();
    const [allPerson, setAllPerson] = useState<IGetAllTaskPerson | undefined>(undefined);
    const [taskInformation, setTaskInformation] = useState<ITaskGeneralInfo>();

    useEffect(() => {
        (async () => {
            await loadTaskInfo();
            await getAllTaskPerson();
        })();
    }, []);

    useEffect(() => {        
        if (taskInformation && defaultBoardId) {
            let bread: IBreadCrumb[] = [
                {
                    label: "Проекты",
                    url: "/projects",
                },
            ];
    
            bread = [...bread, ...findBoard(sideBarProjects, defaultBoardId)];
            bread.push({
                label: taskInformation.shortDescription,
                url: "/task/" + taskInformation.id
            });
            
            setBreadCrumb(bread);
            setNavPanelHighlight(undefined);
        }
    }, [setBreadCrumb, taskInformation, defaultBoardId, sideBarProjects]);

    const loadTaskInfo = async () => {
        let res = await store.getTask({
            taskId: Number(id),
            boardId: boardId ? Number(boardId) : undefined,
        });
        setTaskInformation(res);
        setDefaultBoardId(res?.board.id);
    };

    const getAllTaskPerson = async () => {
        const res = await store.getAllTaskPerson(Number(id));
        setAllPerson(res)
    };

    return (
        <TaskContext.Provider value={{
            taskInformation,
            loadTaskInfo,
            getAllTaskPerson,
            allPerson
            }}>
            <div className="widgets_task_container">
                <div className="widgets_task_container_1">
                    <div className="widgets_task_container_sub">
                        <TaskInformationForm projectId={taskInformation?.projectId}/>
                    </div>
                    <div>
                        <TaskChat />
                    </div>
                </div>
                <div className="widgets_task_container_2">
                    <TaskRightInfo />
                </div>
            </div>
        </TaskContext.Provider>
    );
};

export default Task;
