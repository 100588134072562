import React, {createContext, FC, useContext, useEffect, useState} from 'react';
import { Context } from "../../index";
import "../../globalstyles/page.pc.css"
import '../../pages/Main/MainPage.css'
import '../../pages/UserMonitor/UserMonitor.css'
import '../../component/Project/Tasks/TasksWidget.css'

import { AppContext } from '../../App';
import {Link, useNavigate} from "react-router-dom";
import UserMonitorToolbar from '../../component/UserMonitor/ToolBar/UserMonitorToolbar';
import Tasks, {
    personRowDisplay,
    taskPriorityDisplay,
    taskStatusDisplay,
    taskTypeDisplay, toDisplayDate
} from "../../component/Project/Tasks/Tasks";
import {IGetTasksDisplayData, ITasksDisplay, ITasksDisplayFilters} from "../../models/TaskModels";
import {ITaskFilter} from "../../models/ITaskFilters";
import {ISort} from "../Project/Project";

import starIdle from "../../assets/ButtonIcons/favourite-icon-idle.svg"
import starActive from "../../assets/ButtonIcons/favourite-icon-active.svg"
import unknown_svg from "../../assets/FileIcons/unknown.svg";
import SortDirectionArrow from "../../assets/sortDirectionArrow.svg";
import ExitMark from "../../assets/ButtonIcons/user-monitor-exit-button.svg"
import TaskStatus from "../../component/Shared/TaskStatus";
import TaskColumn from "../../component/Shared/TaskColumn";
import {dateToNiceString} from "../../helpers/dateToNiceString";
import {ISelectedFastFilters} from "../Board/Board";
import {IFilter, IFilterItem} from "../../models/response/IFilterResponse";
import MarkdownPreview from "../../component/Shared/ChatMarkdown/ChatMarkdown";

interface IUserMonitorContext {
    taskFilters: ITasksDisplayFilters
    selectedFastFilters: ISelectedFastFilters;
    changeFastFilters: (filters: ISelectedFastFilters) => void;
}

export const UserMonitorContext = createContext<IUserMonitorContext>({
    taskFilters: { priority: [], type: [], status: [], tag: [] },
    selectedFastFilters: { priority: undefined, type: undefined, tag: undefined },
    changeFastFilters: () => { }
});

const UserMonitor: FC = (index) => {
    const { store } = useContext(Context);
    const { setBreadCrumb, setNavPanelHighlight} = useContext(AppContext);
    const navigate = useNavigate();

    //Задачи
    const [tasks, setTasks] = useState<any[]>([]);
    const [selectedTask, setSelectedTask] = useState<number | undefined>(undefined);
    const [favouriteTasks, setFavouriteTasks] = useState<number[]>([1,7,12,4]);
    const [page, setPage] = useState(1);
    const [scrollEndedTasks, setScrollEndedTasks] = useState<boolean>(false);

    //Фильтры //TODO починить интефейс
    const [taskNameSearch, setTaskNameSearch] = useState<string>("");
    const [useFullFilters, setUseFullFilters] = useState<boolean>(false);
    const [taskFilters, setTaskFilters] = useState<any>({
        priority: undefined,
        tag: undefined,
        type: undefined,
        status: undefined
    });

    //Тогглы
    const [showOnlyFavourite, setShowOnlyFavourite] = useState<boolean>(false);

    //Эти кастомные сеты нужны чтобы не было бага при включении звездочки.
    function setShowOnlyFavouriteFunc(value: boolean){
        setSelectedTask(undefined)
        setShowOnlyFavourite(value)
    }
    const [showCompleted, setShowCompleted] = useState<boolean>(true);
    function setShowCompletedFunc(value: boolean){
        setSelectedTask(undefined)
        setShowCompleted(value)

    }
    //Быстрые фильтры
    const [selectedFastFilters, setSelectedFastFilters] = useState<ISelectedFastFilters>({ priority: undefined, type: undefined, tag: undefined });
    //Сортировки
    const [dataSort, setDataSort] = useState([{ lable: "НАЗВАНИЕ ЗАДАЧИ", sort: false, code: 'nameTask' },{ lable: "ПРОЕКТ", sort: false, code: 'projectTask' },{ lable: "СТОЛБЕЦ", sort: false, code: 'columnTask' }, { lable: "СТАТУС", sort: false, code: 'status' }, { lable: "ПРИОРИТЕТ", sort: false, code: 'priority' }, { lable: "ТИП ЗАДАЧИ", sort: false, code: 'type' }, { lable: "ДАТА", sort: true, code: 'date' }]);
    const [sortAsc, setSortAsc] = useState(false);

    //Избранное
    //Правый бар
    const [totalStats, setTotalStats] = useState<{
        total: number
        ongoing: number
        completed: number
        overdue: number
    } | undefined>(undefined);
    const [showFullComment, setShowFullComment] = useState(false);
    const [showFullDescription, setShowFullDescription] = useState(false);

    useEffect(() => {
        // Пока нет пагинации по скроллу - грузится тут (Ну кстати тут удобно подписаться на изменение элементов управления)
        console.log("USER MONITOR USEEFFECT")
        setBreadCrumb([{label: "Монитор пользователя", url: "monitor"}])
        setNavPanelHighlight("monitor");
        store.getFavouriteTasks().then(res => {
            if(res?.favourited){
                // console.log(res.favourited);
                setFavouriteTasks(res.favourited)
            }
        })
        // loadTasks(0, 10000, {} as any, {} as any) //TODO Решить что делать с подгрузкой
        let filters = taskFilters;
        (filters as any).favourite = showOnlyFavourite;
        (filters as any).onlyOngoing = !showCompleted;
        if(taskNameSearch){
            (filters as any).name = taskNameSearch;
        } else {
            (filters as any).name = undefined;
        }

        store.getMonitorTasks({
            skip: 0,
            take: 1000,
            filters: filters,
            sort: {}
        }).then(res=>{
            // console.log("TASKS:", res?.tasks)
            if (res) {
                setTasks(structuredClone(res.tasks));
                setTotalStats(res.rightBarStats)
                // setTasks(JSON.parse(JSON.stringify(res.tasks)));
            } else {
                setTasks([]);
                // setTasks(JSON.parse(JSON.stringify([])));
            }
        })
    }, [showOnlyFavourite, showCompleted, taskNameSearch]);

    // //TASK FUNCTIONS
    // const loadTasks = async (skip: number, take: number, filters: ITaskFilter, sort: ISort) => {
    //
    //     setScrollEndedTasks(false);
    //     const newPageReq: IGetTasksDisplayData = {
    //         skip: skip,
    //         take: take,
    //         filters: filters as any,
    //         sort: sort
    //     };
    //
    //     const res = await store.getProjectTasksDisplayData(newPageReq) as ITasksDisplay;
    //     if (res !== null) {
    //         setTasks(structuredClone(res.tasks));
    //         // setTasks(JSON.parse(JSON.stringify(res.tasks)));
    //     } else {
    //         setTasks([]);
    //         // setTasks(JSON.parse(JSON.stringify([])));
    //     }
    //
    // }
    // const appendTasks = async (skip: number, take: number, filters: ITaskFilter, sort: ISort) => {
    //     const newPageReq: IGetTasksDisplayData = {
    //         skip: skip,
    //         take: take,
    //         filters: filters as any,
    //         sort: sort
    //     };
    //
    //     const res = await store.getProjectTasksDisplayData(newPageReq) as ITasksDisplay;
    //     if (res?.tasks && res?.tasks?.length > 0) {
    //         setTasks([...tasks, ...res.tasks]);
    //     } else { //TODO check res status
    //         setScrollEndedTasks(true);
    //     }
    //
    // }
    // const handleLoadPageTasks = async (skip: number, take: number, filters: ITaskFilter, sort:ISort) => {
    //     await appendTasks(skip, take, filters, sort);
    // }

    async function getFullTaskData(){

    }

    function loadNewPage(){

    }

    function updateFilters(){

    }

    function onRowClick(event: any){
        //Это чтобы строчки норм кликались
        let _target: any = event.target

        if (event.target.classList.contains('click-highlighted-row')) {
            // console.log('Target is highlightable:', event.target);
        } else {
            let highlightableParent = _target.closest('.click-highlighted-row');
            if(highlightableParent){
                _target = highlightableParent
            }
        }

        // console.log('Event Target:', _target);

        if(selectedTask == _target.id){
            setSelectedTask(undefined)
        } else {
            setSelectedTask(_target.id);
        }
    }

    async function onFavouriteClick(taskId: any){
        console.log(favouriteTasks)
        if(favouriteTasks.some(xx=>xx == taskId)){
            let newFavourite = favouriteTasks.filter(xx=>xx != taskId)
            updateFavourite({
                favourited: newFavourite
            });
        } else {
            let newFavourite = [...favouriteTasks, taskId]
            updateFavourite({
                favourited: newFavourite
            });
        }
    }

    async function updateFavourite(newFavourite: { favourited: number[] }){
        let tasksUpdated = await store.setFavouriteTasks(newFavourite)

        if(tasksUpdated.favourited){
            setFavouriteTasks(tasksUpdated.favourited)
        }
    }
    function onSortChange(event: any){

    }
    function addSort(label: string, code: string) {
        let currentSort = dataSort.find(i => i.lable === label);
        let prevtSort = dataSort.find(i => i.sort === true);

        if (currentSort && prevtSort) {
            let indexCurrentSort = dataSort.indexOf(currentSort);
            let indexPrevtSort = dataSort.indexOf(prevtSort);

            currentSort.sort = true;
            prevtSort.sort = false;

            let left = dataSort.slice(0, indexCurrentSort);
            let rigth = dataSort.slice(indexCurrentSort + 1);
            let newDataSort = [...left, currentSort, ...rigth];
            let left2 = newDataSort.slice(0, indexPrevtSort);
            let rigth2 = newDataSort.slice(indexPrevtSort + 1);

            newDataSort = [...left2, prevtSort, ...rigth2];
            setDataSort(newDataSort);
            setSortAsc(false);
            onSortChange(code);
        }
    }

    const changeFastFilters = (fastFilters: ISelectedFastFilters) => {
        setSelectedFastFilters(fastFilters);

        // const boardFiltersCopy: IFilter[] = JSON.parse(
        //     // JSON.stringify(boardFilters)
        // );
        //
        // boardFiltersCopy.forEach((f) => {
        //     if (f.nameForFilter === "priority") {
        //         (f.items as IFilterItem[])?.forEach((i) => {
        //             i.checked = false;
        //             if (i.id == fastFilters.priority?.id) i.checked = true;
        //         });
        //     }
        //     if (f.nameForFilter === "typeTask") {
        //         (f.items as IFilterItem[])?.forEach((i) => {
        //             i.checked = false;
        //             if (i.id == fastFilters.type?.id) i.checked = true;
        //         });
        //     }
        // });
        // let checkedFiltersCopy = JSON.parse(
        //     JSON.stringify(checkedBoardFilters)
        // );
        //
        // checkedFiltersCopy.priority = fastFilters.priority
        //     ? [fastFilters.priority.id]
        //     : undefined;
        // checkedFiltersCopy.typeTask = fastFilters.type
        //     ? [fastFilters.type.id]
        //     : undefined;
        //
        // setCheckedBoardFilters(checkedFiltersCopy);
        // setBoardFilters(boardFiltersCopy);
        // setForceFilterChange(true);
    };

    function starButton(taskId: number){
        //КНОПКА НЕ КРАСИВАЯ, ПРИ НАВЕДЕНИИ НЕТ ЭФФЕКТОВ //TODO сделать красиво (Чтобы эффект по форме звездочки появлялся.)
        if(favouriteTasks.some(xx=>xx == taskId)){
            return (
                <img src={starActive} className="star-button-img" onClick={(event)=> {
                    event.stopPropagation()
                    onFavouriteClick(taskId)
                }}/>
            )
        } else {
            return (
                <img src={starIdle} className="star-button-img" onClick={(event)=> {
                    event.stopPropagation()
                    onFavouriteClick(taskId)
                }}/>
            )
        }
    }

    //Это отрисовочка цветной фигнюшки для колонки и подколонки.
    const taskColumnDisplay = (task: any) => {
        if(task.columns.proxy){
            return (<div className="column-display-container">
                <div>
                    <TaskColumn name={task.columns.main.name} color={task.columns.main.color}/>
                    {/*<a className="column-display-container-slash">/</a>*/}
                </div>
                <TaskColumn name={task.columns.proxy.name} color={task.columns.proxy.color}/>
            </div>)
        } else {
            return (<div className="column-display-container">
                <TaskColumn name={task.columns.main.name} color={task.columns.main.color}/>
            </div>)
        }
    };

    ////TODO мб как -то это оптимизировать, дублируется не красиво.
    const ShowFullComment = (task: any) => {
        if(task.lastComments[0]?.text?.length > 200){ //TODO продумать ограничение
            return (<a className="show-all" onClick={event => {
                setShowFullComment(!showFullComment)
            }}>Показать полностью</a>)
        } else {
            return (<></>)
        }
    };
    const ShowFullDescription = (task: any) => {
        if (task.description?.length > 200) { //TODO продумать ограничение
            return (<a className="show-all" onClick={event => {
                setShowFullDescription(!showFullDescription)
            }}>Показать полностью</a>)
        } else {
            return (<></>)
        }
    };

    function isOverflown(element: any) {
        return (
            element.scrollHeight > element.clientHeight ||
            element.scrollWidth > element.clientWidth
        );
    }

    //Вот это попробовать вызвать в useEffect - после рендера
    function checkUnfoldables() {
        let descriptionField = document.querySelector('#full-description-foldable-field');
        let commentField = document.querySelector('#last-comment-foldable-field');
        //Тут для каждой вызвать функцию проверяющую оверфлоу
        //Расставить состояния скрывашек
    }

    const rightMenu = () => {
        if (selectedTask) {
            let task = tasks?.find(xx => xx.id == selectedTask)
            return (
                <div>
                    <div className="exit-button" onClick={event => {
                        setSelectedTask(undefined)
                    }}>
                        <img src={ExitMark} alt="Закрыть задачу"/>
                    </div>
                    <div className="right-bar-top-header break-word">
                        {task.name}
                    </div>
                    <div className="user-monitor-right-bar-block">
                        <div id="full-description-foldable-field" className={`block-text ${showFullDescription ? "" : "folded"}`}>
                            {task.description}
                        </div>
                        {ShowFullDescription(task)}
                    </div>
                    <div className="user-monitor-right-bar-block-divider"></div>
                    <div className="user-monitor-right-bar-block">
                        <div className="block-name">ПОСЛЕДНИЕ КОММЕНТАРИИ</div>
                        {task.lastComments[0] ?
                        <div className="last-comments-block">
                            <div className="author">
                                <div className="name">
                                    %COMMENT AUTHOR NAME%
                                </div>
                                <div className="relation-to-task">
                                    ИСПОЛНИТЕЛЬ
                                </div>
                            </div>
                            <div className="date">
                                {dateToNiceString(new Date(task.lastComments[0]?.date), "month dd.yyyy")}
                            </div>
                            <div id="last-comment-foldable-field" className={`comment ${showFullComment ? "" : "folded"}` }>
                                <MarkdownPreview content={task.lastComments[0]?.text}/>
                                {task.lastComments[0]?.text}
                            </div>
                        </div> : <></>}
                            {ShowFullComment(task)}

                    </div>
                    <div className="user-monitor-right-bar-block-divider"></div>
                    <div className="user-monitor-right-bar-block">
                        <div className="block-name">ПОСЛЕДНИЕ ИЗМЕНЕНИЯ</div>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <div className="right-bar-top-header">
                        Общие сведения
                    </div>
                    <div className="user-monitor-right-menu-item">
                        <label className="a_header_name_16">ВСЕГО ЗАДАЧ</label>
                        <a>{totalStats?.total ?? "-"}</a>
                    </div>
                    <div className="user-monitor-right-menu-item">
                        <label className="a_header_name_16">ЗАДАЧ В РАБОТЕ</label>
                        <a>{totalStats?.ongoing ?? "-"}</a>
                    </div>
                    <div className="user-monitor-right-menu-item">
                        <label className="a_header_name_16">ВЫПОЛНЕНО ЗАДАЧ</label>
                        <a>{totalStats?.completed ?? "-"}</a>
                    </div>
                    <div className="user-monitor-right-menu-item">
                        <label className="a_header_name_16">ПРОСРОЧЕНО ЗАДАЧ</label>
                        <a className="bad">{totalStats?.overdue ?? "-"}</a>
                    </div>
                </div>
            )
        }
    }

    return (
        <UserMonitorContext.Provider value={{
            taskFilters,
            selectedFastFilters,
            changeFastFilters
        }}>
            <div className="user-monitor-page-main-container">
                <div className="user-monitor-main-widget-area">
                    <div className="user-monitor-top-toolbar card-shadow">
                        <UserMonitorToolbar
                            setShowCompleted={setShowCompletedFunc} setShowOnlyFavourite={setShowOnlyFavouriteFunc}
                            showCompleted={showCompleted} showOnlyFavourite={showOnlyFavourite}
                            taskNameSearch={taskNameSearch} setTaskNameSearch={setTaskNameSearch}
                        />
                    </div>
                    <div className="user-monitor-widget-rows-display-part card-shadow">
                        <div className="user-monitor-widget-rows-display-part-table-container">
                            <table>
                                <thead>
                                <tr>
                                    <th></th>
                                    {dataSort && dataSort.map(i => (
                                        i.sort ? (sortAsc ?
                                                <th key={i.code} onClick={() => onSortChange(i.code)}
                                                    className='a_header_name_16 no-highlight sort-direction-changing-header desc'>{i.lable}<img
                                                    src={SortDirectionArrow}/></th>
                                                : <th key={i.code} onClick={() => onSortChange(i.code)}
                                                      className='a_header_name_16 no-highlight sort-direction-changing-header'>{i.lable}<img
                                                    src={SortDirectionArrow}/></th>) :
                                            <th key={i.code} onClick={() => addSort(i.lable, i.code)}
                                                className='a_header_name_16 sort-direction-changing-header'>{i.lable}</th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody>
                                {tasks?.map(task => (
                                    <tr id={task.id} key={task.id}
                                        className={`click-highlighted-row ${selectedTask == task.id ? "selected" : ""}`}
                                        onClick={onRowClick}>
                                        <td className="first">
                                            {starButton(task.id)}
                                        </td>
                                        <td className="name_colum user-monitor-widget-flexy-task-name-td">
                                            <div className="user-monitor-task-name">
                                                <Link to={`/task/${task.id}`}
                                                      style={{color: "inherit", textDecoration: "none"}}>
                                                    {task.name}
                                                </Link>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <Link to={`/project/${task.project.id}`}
                                                      style={{color: "inherit", textDecoration: "none"}}>
                                                    {task.project.name}
                                                </Link>
                                            </div>
                                        </td>
                                        <td>{taskColumnDisplay(task)}</td>
                                        <td>{taskStatusDisplay(task)}</td>
                                        <td>{taskPriorityDisplay(task)}</td>
                                        <td>{taskTypeDisplay(task)}</td>
                                        <td className="last">{toDisplayDate(task.createdAt)}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="user-monitor-right-bar-container card-shadow">
                    {rightMenu()}
                </div>
            </div>
        </UserMonitorContext.Provider>
    );
}

export default UserMonitor;