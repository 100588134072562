import React, {FC, useContext, useEffect, useState} from 'react';
import "./SystemUsers.css"
import "../RolesSystem/RolesSystem.css"
import BtnDelete from "../../buttons/btnDelete.component/BtnDelete";
import EditIcon from "../../../assets/edit.svg";
import BtnAdd from "../../buttons/btnAdd.component/BtnAdd";
import {Context} from "../../../index";
import {
    IGetAllUsersWithSystemRolesChecked
} from "../../../models/IGetAllUsersWithSystemRoles";
import ArrowSub from "../../../assets/arrowDown.svg";
import ArrowRight from "../../../assets/ArrowRight.svg";
import ArrowUp from "../../../assets/arrow_up.svg";
import ArrowDown from "../../../assets/arrow_down.svg";
import PersonIcon from "../../../assets/avatar.svg"
import DeletedStatus from "../../../assets/addTeg.svg"
import {IGetAllRoles} from "../../../models/IGetAllRoles";
import Cross from "../../../assets/cancelGreey.svg";
import {IEditUser} from "../../../models/IEditUser";
import {ISystemDeletePerson} from "../../../models/ISystemDeletePerson";
import {ISystemInvitePerson} from "../../../models/ISystemInvitePerson";
import {InputText} from "primereact/inputtext";
import {IGetRole} from "../../../models/IGetRole";

const SystemUsers: FC = (index) => {
    const { store } = useContext(Context);
    const [allUsers, setAllUsers] = useState<IGetAllUsersWithSystemRolesChecked[] | undefined>([]);
    const [allSaveUsers, setAllSaveUsers] = useState<IGetAllUsersWithSystemRolesChecked[] | undefined>([]);
    const [allRoles, setAllRoles] = useState<IGetRole[] | undefined>([]);
    const [cheksTrue, setCheksTrue] = useState<boolean>(false);
    const [editShow, setEditShow] = useState<boolean>(false);
    const [editWidgetShow, setEditWidgetShow] = useState<boolean>(false);
    const [currentEditUser, setCurrentEditUser] = useState<{ id: number, systemRole: {id: number, name: string}, status: string, personRolesId: number } | undefined>();
    const [stateArrowDateEnter, setStateArrowDateEnter] = useState<1 | 2 | 3>(1);
    const [stateArrowDateUpdate, setStateArrowDateUpdate] = useState<1 | 2 | 3>(1);
    const [stateStatus, setStateStatus] = useState<string>();
    const [showDeleteMessage, setShowDeleteMessage] = useState<boolean>(false);
    const [deletePersonCount, setDeletePersonCount] = useState<number>(0);
    const [showAddWidget, setShowAddWidget] = useState<boolean>(false);
    const [newUser, setNewUser] = useState<ISystemInvitePerson>();
    const [email, setEmail] = useState<string>();
    const [passwordRepeat, setPasswordRepeat] = useState<string>();
    const [firstName, setFirstName] = useState<string>();
    const [surname, setSurname] = useState<string>();
    const [middleName, setMiddleName] = useState<string>();
    const [roleNewUser, setRoleNewUser] = useState<number>(0);
    const [roleNewUserError, setRoleNewUserError] = useState<string>();
    const [emailError, setEmailError] = useState<string>();
    const [nameError, setNameError] = useState<string>();
    const [surnameError, setSurnameError] = useState<string>();
    const [showAllStatus, setShowAllStatus] = useState<boolean>(false);
    const [statusAll, setStatusAll] = useState<string[]>(["Активен","Ждет активацию","Приглашен","Заблокирован"]);

    function getUsers() {
        (async () => {
            try {
                const users = await store.getAllUsersWithSystemRoles();
                if (users) {
                    let data: IGetAllUsersWithSystemRolesChecked[] = [];
                    users.map((xx) => {
                        data.push({
                            chek: false,
                            user: xx
                        })
                    });
                    setAllUsers(data);
                }
                const roles = await store.getNameAllRoles();
                setAllRoles(roles);
            } catch(err) {
                console.log(err)
            }
        })();
        setStateStatus(undefined);
        setStateArrowDateUpdate(1);
        setStateArrowDateEnter(1);
    }

    function viewBlock(idView: string, imageOne: string, imageTwo: string) {
        const element = document.getElementById(idView);
        const elementOne = document.getElementById(imageOne);
        const elementTwo = document.getElementById(imageTwo);

        if (element) {
            if (element.style.display === 'none') {
                element.style.display = 'block';
            } else {
                element.style.display = 'none';
            }
        } else {
            console.error(`Элемент с ID ${idView} не найден.`);
        }

        if (elementOne) {
            if (elementOne.style.display === 'none') {
                elementOne.style.display = 'block';
            } else {
                elementOne.style.display = 'none';
            }
        } else {
            console.error(`Элемент с ID ${elementOne} не найден.`);
        }

        if (elementTwo) {
            if (elementTwo.style.display === 'none') {
                elementTwo.style.display = 'block';
            } else {
                elementTwo.style.display = 'none';
            }
        } else {
            console.error(`Элемент с ID ${elementTwo} не найден.`);
        }
    };

    function getColor(status: string) {
        if (status == "Активен") {
            return "#699F4D";
        } else if (status == "Ждет активацию") {
            return "#EA580C";
        } else if (status == "Приглашен") {
            return "#149cce";
        } else if (status == "Заблокирован") {
            return "#BA4E41";
        } else {
            return "#BA4E41";
        }
    }

    function showDeleteFunc() {
        let deletePerson = allUsers?.filter(xx => xx.chek == true).length;
        setCheksTrue(false);
        setEditShow(false);
        setDeletePersonCount(deletePerson ? deletePerson : 0);
        setShowDeleteMessage(true);
    };

    function deletePersonFunc() {
        let getPersonToBlock = allUsers?.filter(xx => xx.chek == true);
        if (getPersonToBlock) {
            (async () => {
                try {
                    const data: ISystemDeletePerson[] = getPersonToBlock.map(xx => {
                        return {
                            userId: xx.user.id
                        }
                    });
                    const delPers = await store.deleteSystemPerson(data);
                    getUsers();
                } catch(err) {
                    console.log(err)
                }
            })();
        }
        closeDelete();
    };

    function closeDelete() {
        setShowDeleteMessage(false);
    };

    function closeAdd() {
        setFirstName("");
        setSurname("");
        setEmail("");
        setRoleNewUser(0);
        setNameError("");
        setSurnameError("");
        setMiddleName("");
        setEmailError("");
        setRoleNewUserError("");
        setShowAddWidget(false);
    };

    function cropFIO(fio: string): string {
        let cropMass = fio.split(" ");
        let cropFio = "";
        let count = 0;
        cropMass.forEach(xx => {
            if (count == 0) {
                cropFio += xx + " ";
                count += 1;
            } else {
                cropFio += xx.slice(0,1) + ".";
            }
        })

        return cropFio;
    }

    function addPersonFunc() {
        if (firstName?.length != 0 && surname?.length != 0 && email?.length != 0 && emailError?.length == 0 &&
            surnameError?.length == 0 && nameError?.length == 0 && roleNewUser != 0) {
            setNameError("");
            setSurnameError("");
            setEmailError("");
            setRoleNewUserError("");

            let newUser: ISystemInvitePerson = {
                name: firstName!,
                surname: surname!,
                middleName: middleName!,
                email: email!,
                roleId: roleNewUser!
            };

            (async () => {
                try {
                    let addNewUser = await store.systemInviteUser(newUser);
                    getUsers();
                } catch(err) {
                    console.log(err)
                }
            })();
            setFirstName("");
            setSurname("");
            setMiddleName("");
            setEmail("");
            setRoleNewUser(0);
            setShowAddWidget(false);
        } else {
            nameError?.length == 0 ? setNameError("Заполните имя") : setNameError("");
            surnameError?.length == 0 ? setSurnameError("Заполните фамилию") : setSurnameError("");
            emailError?.length == 0 ? setEmailError("Введите email") : setEmailError("");
            roleNewUser == 0 ? setRoleNewUserError("Выберите роль") : setRoleNewUserError("");
        }
    }

    function saveUser() {
        if (currentEditUser) {
            const editUser: IEditUser = {
                id: currentEditUser.id,
                status: currentEditUser.status,
                roleId: currentEditUser.systemRole.id,
                personRolesId: currentEditUser.personRolesId
            };

            (async () => {
                try {
                    const data = await store.editUser(editUser);
                    getUsers();
                } catch(err) {
                    console.log(err)
                }
            })();
        }

        setCheksTrue(false);
        setEditShow(false);
        setCurrentEditUser(undefined);
        setAllSaveUsers(undefined);
        setEditWidgetShow(false);
    }

    function editPerson() {
        let currentUser = allUsers?.find(xx => xx.chek == true);
        if (currentUser) {
            const allSaveUsersCopy = JSON.parse(JSON.stringify(allUsers));
            setAllSaveUsers(allSaveUsersCopy);
            setCurrentEditUser({
                id: currentUser!.user.id,
                systemRole: {
                    id: currentUser!.user.systemRole.id,
                    name: currentUser!.user.systemRole.name
                },
                status: currentUser!.user.status,
                personRolesId: currentUser!.user.personRolesId
            })
            setEditWidgetShow(true);
        }
    };

    const validateName = (): boolean => {
        let nameErr = undefined;

        if (!firstName || firstName.length === 0) {
            nameErr = "Заполните имя";
        }

        setNameError(nameErr);
        return nameErr !== undefined;
    };

    const validateSurname = (): boolean => {
        let surnameErr = undefined;

        if (!surname || surname.length === 0) {
            surnameErr = "Заполните фамилию";
        }

        setSurnameError(surnameErr);
        return surnameErr !== undefined;
    };

    const validateEmail = (): boolean => {
        let emailErr = undefined;

        if (!email) {
            emailErr = "Введите email";
        } else if (!isValidEmail(email)) {
            emailErr = "Некорректный email";
        }

        setEmailError(emailErr);
        return emailErr !== undefined;
    };

    const isValidEmail = (email: string): boolean => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    function addPerson() {

    };

    function closeFunc() {
        setCheksTrue(false);
        setEditShow(false);
        setCurrentEditUser(undefined);
        setAllUsers(allSaveUsers);
        setAllSaveUsers(undefined);
        setEditWidgetShow(false);
    }

    function changeRole(idRole: number, idPerson: number) {
        if (allUsers && allRoles) {
            const userToUpdate = allUsers.find(user => user.user.id === idPerson);
            if (userToUpdate) {
                const newRole = allRoles.find(role => role.id === idRole);
                console.log(newRole)
                if (newRole) {
                    userToUpdate.user.systemRole = { id: idRole, name: newRole.roleName };
                    // setAllUsers([...allUsers]);

                    if (currentEditUser) {
                        let data = currentEditUser;
                        data.systemRole.name = newRole.roleName;
                        data.systemRole.id = newRole.id;

                        setCurrentEditUser(data);
                    }

                    const editUser: IEditUser = {
                        id: userToUpdate.user.id,
                        status: userToUpdate.user.status,
                        roleId: userToUpdate.user.systemRole.id,
                        personRolesId: userToUpdate.user.personRolesId
                    };

                    (async () => {
                        try {
                            const data = await store.editUser(editUser);
                            getUsers();
                        } catch(err) {
                            console.log(err)
                        }
                    })();

                } else {
                    console.error(`Role with ID ${idRole} not found.`);
                }
            } else {
                console.error(`User with ID ${idPerson} not found.`);
            }
        } else {
            console.error("Either allUsers or allRoles is undefined.");
        }
    }

    function sortDateUpdate() {
        setStateArrowDateEnter(1);
        setStateStatus("");
        if (stateArrowDateUpdate == 1) {
            let data: IGetAllUsersWithSystemRolesChecked[] = JSON.parse(JSON.stringify(allUsers));
            setAllUsers(data.sort((a, b) => {
                // Сравниваем даты, используя оператор сравнения <
                if (new Date(a.user.dateUpdate) < new Date(b.user.dateUpdate)) {
                    return -1; // a идет перед b
                } else if (new Date(a.user.dateUpdate) > new Date(b.user.dateUpdate)) {
                    return 1; // b идет перед a
                } else {
                    return 0; // a и b равны
                }
            }));
            setStateArrowDateUpdate(2)
        } else if (stateArrowDateUpdate == 2) {
            let data: IGetAllUsersWithSystemRolesChecked[] = JSON.parse(JSON.stringify(allUsers));
            setAllUsers(data.sort((a, b) => {
                // Сравниваем даты, используя оператор сравнения <
                if (new Date(a.user.dateUpdate) < new Date(b.user.dateUpdate)) {
                    return 1; // a идет перед b
                } else if (new Date(a.user.dateUpdate) > new Date(b.user.dateUpdate)) {
                    return -1; // b идет перед a
                } else {
                    return 0; // a и b равны
                }
            }));
            setStateArrowDateUpdate(3)
        } else {
            setStateArrowDateUpdate(1)
        }
    }

    function sortDatentry() {
        setStateArrowDateUpdate(1);
        setStateStatus("");
        if (stateArrowDateEnter == 1) {
            let data: IGetAllUsersWithSystemRolesChecked[] = JSON.parse(JSON.stringify(allUsers));
            setAllUsers(data.sort((a, b) => {
                if (new Date(a.user.dateLastIn) < new Date(b.user.dateLastIn)) {
                    return -1;
                } else if (new Date(a.user.dateLastIn) > new Date(b.user.dateLastIn)) {
                    return 1;
                } else {
                    return 0;
                }
            }));
            setStateArrowDateEnter(2)
        } else if (stateArrowDateEnter == 2) {
            let data: IGetAllUsersWithSystemRolesChecked[] = JSON.parse(JSON.stringify(allUsers));
            setAllUsers(data.sort((a, b) => {
                if (new Date(a.user.dateLastIn) < new Date(b.user.dateLastIn)) {
                    return 1;
                } else if (new Date(a.user.dateLastIn) > new Date(b.user.dateLastIn)) {
                    return -1;
                } else {
                    return 0;
                }
            }));
            setStateArrowDateEnter(3)
        } else {
            setStateArrowDateEnter(1)
        }
    }

    function sortStatus(status: string) {
        setStateStatus(status);
        setStateArrowDateUpdate(1);
        setStateArrowDateEnter(1);
        let data: IGetAllUsersWithSystemRolesChecked[] = JSON.parse(JSON.stringify(allUsers));
        const withStatus = data.filter(user => user.user.status === status);
        const withoutStatus = data.filter(user => user.user.status !== status);
        const sortedUsers = [...withStatus, ...withoutStatus];
        setAllUsers(sortedUsers);
    }

    function dateToString(dateISO: string) {
        if (dateISO) {
            let newDateString = new Date(dateISO);
            return String(newDateString.getDate() + "." +  (newDateString.getMonth() + 1) + "." + newDateString.getFullYear() +
                 " в " + newDateString.getHours() + ":" + newDateString.getMinutes());
        } else {
            return "-"
        }
    }

    function changeStatus(chek: boolean) {
        let findUser = allUsers!.find(xx => xx.user.id == currentEditUser?.id)
        console.log(currentEditUser?.status)
        const updatedUser: { id: number, systemRole: { id: number, name: string }, status: string, personRolesId: number } = {
            id: currentEditUser!.id,
            systemRole: {
                id: currentEditUser!.systemRole.id,
                name: currentEditUser!.systemRole.name
            },
            status: findUser?.user.status == "Ждет активацию" && currentEditUser?.status == "Активен" ?
                "Ждет активацию" :
                chek ?
                    "Заблокирован" :
                    findUser?.user.status == "Приглашен" || findUser?.user.status == "Заблокирован" && findUser?.user.user.isActivate != true && findUser?.user.dateLastIn.length == 0 ?
                        "Приглашен" : "Активен",
            personRolesId: findUser!.user.personRolesId
        };

        console.log(updatedUser)
        setCurrentEditUser(updatedUser);
    }

    function changeChek(idUser: number, chek: boolean) {
        const newData = allUsers?.map((xx) => {
            if (xx.user.id === idUser) {
                return {
                    ...xx,
                    chek: chek,
                };
            }
            return xx;
        });

        let getCountChek = newData!.filter(xx => xx.chek == true).length;
        if (getCountChek == 0) {
            setCheksTrue(false)
            setEditShow(false);
        } else if (getCountChek  == 1) {
            setCheksTrue(true);
            setEditShow(true);
        } else {
            setEditShow(false);
            setCheksTrue(true)
        }
        setAllUsers(newData);
    }

    const handleRoleChange = (block: string, idRole: number, idUser: number) => {
        changeRole(idRole, idUser);
        viewBlock(block, "SystemGroupArrowSub" + String(idUser), "SystemGroupArrowRight" + String(idUser));
    };

    const handleRoleChangeWidget = (block: string, arrowSub: string, arrowRight: string, idRole: number, idUser: number) => {
        changeRole(idRole, idUser);
        viewBlock(block, arrowSub, arrowRight);
    };

    const handleRoleChangeRoleNewPerson = (block: string, arrowSub: string, arrowRight: string, idRole: number) => {
        let findRole = allRoles?.find(xx => xx.id == idRole);
        setRoleNewUser( findRole ? findRole.id : 0)
        viewBlock(block, arrowSub, arrowRight);
    };

    useEffect(() => {
        getUsers();
    }, []);

    return (
        <div className="system--users__component">
            {showAddWidget ?
                <div className="system--users__component--widget__Edit">
                    <div className="system--users__component--widget__add">
                        <div className="system--users__component--widget__add--header">
                            <div>
                                Новый пользователь
                            </div>
                            <div className='add__edit--role__component--header__img'>
                                <img src={Cross} onClick={()=>(closeAdd())}/>
                            </div>
                        </div>
                        <div className='system--users__component--widget__add--header__block'>
                            <div className='system--users__component--widget__add--header__text'>
                                Имя
                            </div>
                            <div className='system--users__component--widget__add--header__input'>
                                <InputText
                                    id="login_first_name"
                                    value={firstName}
                                    placeholder="Имя"
                                    onBlur={validateName}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => setFirstName(e.target.value)}
                                />
                                {nameError ? (
                                    <p className="system--users__component--widget__add--header__input--error_message">{nameError}</p>
                                ) : null}
                            </div>
                            <div className='system--users__component--widget__add--header__text'>
                                Фамилия
                            </div>
                            <div className='system--users__component--widget__add--header__input'>
                                <InputText
                                    id="login_surname"
                                    value={surname}
                                    placeholder="Фамилия"
                                    onBlur={validateSurname}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => setSurname(e.target.value)}
                                />
                                {surnameError ? (
                                    <p className="system--users__component--widget__add--header__input--error_message">
                                        {surnameError}
                                    </p>
                                ) : null}
                            </div>
                            <div className='system--users__component--widget__add--header__text'>
                                Отчество
                            </div>
                            <div className='system--users__component--widget__add--header__input'>
                                <InputText
                                    id="middle_name"
                                    value={middleName}
                                    placeholder="Отчество"
                                    autoComplete="off"
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => setMiddleName(e.target.value)}
                                />
                            </div>
                            <div className='system--users__component--widget__add--header__text'>
                                Почта
                            </div>
                            <div className='system--users__component--widget__add--header__input'>
                                <InputText
                                    id="login_email"
                                    value={email}
                                    placeholder="email"
                                    onBlur={validateEmail}
                                    autoComplete="email"
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => setEmail(e.target.value)}
                                />
                                {emailError ? (
                                    <p className="system--users__component--widget__add--header__input--error_message">{emailError}</p>
                                ) : null}
                            </div>
                            <div className='system--users__component--widget__add--header__text'>
                                Роль
                            </div>
                            <div className="system--users__component--widget__add--header__role">
                                <div className='system--users__component--widget__Edit--block__role'>
                                    <div className='system--users__component--widget__Edit--block__role--text'>
                                        {roleNewUser ? allRoles!.find(xx => xx.id == roleNewUser)!.roleName : "Выберите роль"}
                                    </div>
                                    <button
                                        onClick={() => (viewBlock("WidgetRoleAdd", "WidgetRoleArrowSubAdd", "WidgetRoleArrowRightAdd"))}
                                        className='add__edit--role__component__widget--button__show--more'>
                                        <img src={ArrowRight} id={"WidgetRoleArrowSubAdd"}
                                             style={{display: 'none'}}/>
                                        <img src={ArrowSub} id={"WidgetRoleArrowRightAdd"}/>
                                    </button>
                                </div>
                                <div className='system--users__component--table__content--td__role--choise'
                                     id={"WidgetRoleAdd"} style={{display: "none"}}>
                                    <div className='system--users__component--widget__Edit--block__role--absolut'
                                         style={{background: "white"}}>
                                        {allRoles && allRoles.map((role) => (
                                            <div
                                                className='system--users__component--table__content--td__role--choise__one'
                                                onClick={() => (handleRoleChangeRoleNewPerson("WidgetRoleAdd", "WidgetRoleArrowSubAdd", "WidgetRoleArrowRightAdd", role.id))}>
                                                {role.roleName}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {roleNewUserError ? (
                                    <p className="system--users__component--widget__add--header__input--error_message">
                                        {roleNewUserError}
                                    </p>
                                ) : null}
                            </div>
                            <div>
                                <div className="system--users__component--widget__button">
                                    <button onClick={() => (closeAdd())}
                                            className='add__edit--role__component--buttons__cansel'>
                                        Отмена
                                    </button>
                                    <button onClick={addPersonFunc}
                                            className='add__edit--role__component--buttons__create'>
                                        Подтвердить
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <></>
            }
            {showDeleteMessage ?
                <div className="system--users__component--widget__Edit">
                    <div className="system--users__component--widget__delete">
                        <div>
                            Вы уверены что хотите
                            заблокировать {deletePersonCount == 1 ? "выбранного пользователя" : "выбранных пользователей"}?
                        </div>
                        <div>
                            <div className="system--users__component--widget__button">
                                <button onClick={() => (setShowDeleteMessage(false))}
                                        className='add__edit--role__component--buttons__cansel'>
                                    Отмена
                                </button>
                                <button onClick={deletePersonFunc}
                                        className='add__edit--role__component--buttons__create'>
                                    Подтвердить
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <></>
            }
            {editWidgetShow ?
                <div className="system--users__component--widget__Edit">
                    <div className="system--users__component--widget__Edit--block">
                        <div className="system--users__component--widget__Edit--block__header">
                            <div className="system--users__component--widget__Edit--block__header--name">
                                Редатирование пользователя
                            </div>
                            <div className='add__edit--role__component--header__img'>
                                <img src={Cross} onClick={closeFunc}/>
                            </div>
                        </div>
                        <div className='system--users__component--widget__Edit--block__role--new'>
                            <div className='system--users__component--widget__Edit--block__role--name__h'>
                                Системная роль
                            </div>
                            <div className="system--users__component--widget__Edit--block__right">
                                <div className='system--users__component--widget__Edit--block__role--new'>
                                    <div>
                                        {currentEditUser?.systemRole.name}
                                    </div>
                                    <button
                                        onClick={() => (viewBlock("WidgetRole", "WidgetRoleArrowSub", "WidgetRoleArrowRight"))}
                                        className='add__edit--role__component__widget--button__show--more'>
                                        <img src={ArrowRight} id={"WidgetRoleArrowSub"}
                                             style={{display: 'none'}}/>
                                        <img src={ArrowSub} id={"WidgetRoleArrowRight"}/>
                                    </button>
                                </div>
                                <div className='system--users__component--table__content--td__role--choise' id={"WidgetRole"} style={{display: "none"}}>
                                    <div className='system--users__component--widget__Edit--block__role--absolut' style={{background: "white"}}>
                                        {allRoles && allRoles.map((role) => (
                                            <div className='system--users__component--table__content--td__role--choise__one'
                                                 onClick={() => (handleRoleChangeWidget("WidgetRole", "WidgetRoleArrowSub", "WidgetRoleArrowRight", role.id, currentEditUser!.id))}>
                                                {role.roleName}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='system--users__component--widget__Edit--block__role--new'>
                            <div className='system--users__component--widget__Edit--block__role--name__h'>
                                Статус
                            </div>
                            <div className="system--users__component--widget__Edit--block__right">
                                <div className="system--users__component--widget__Edit--block__status">
                                    <div className="system--users__component--widget__Edit--block__status--width">
                                        <div
                                            className='system--users__component--table__content--td_status system--users__component--widget__Edit--block__status--shoise'
                                            style={{
                                                border: "1px solid " + getColor(currentEditUser!.status),
                                                color: getColor(currentEditUser!.status)
                                            }}>
                                            {currentEditUser && currentEditUser!.status}
                                        </div>
                                    </div>
                                </div>
                                {currentEditUser!.status != "Заблокирован" && currentEditUser!.status != "Ждет активацию" ?
                                    <div onClick={() => (changeStatus(true))} className="system--users__component--widget__Edit--block__style--action">
                                        <img src={Cross}/>
                                    </div>
                                :
                                    <div onClick={() => (changeStatus(false))} className="system--users__component--widget__Edit--block__style--action__aprove">
                                        <img src={DeletedStatus}/>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="system--users__component--widget__button">
                            <button onClick={closeFunc} className='add__edit--role__component--buttons__cansel'>
                                Отмена
                            </button>
                            <button onClick={saveUser} className='add__edit--role__component--buttons__create'>
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
                :
                <></>
            }
            <div className='roles--system__component__buttons--block'>
                <div className='roles--system__component__buttons--block__size'>
                    {cheksTrue ?
                        <div className='roles--system__component__buttons--block__size--hiden'>
                            <BtnDelete onClickFunc={showDeleteFunc} permission={true}/>
                            {editShow ?
                                <button className='roles--system__component__buttons--block__edit' onClick={editPerson}>
                                    <img src={EditIcon}/>
                                </button>
                                :
                                <></>
                            }
                        </div>
                        :
                        <></>
                    }
                    <BtnAdd onClickFunc={()=>(setShowAddWidget(true))} type='add' permission={true}/>
                </div>
            </div>
            <div>
                <table className="system--users__component--table">
                    <tr className="system--users__component--table__tr">
                        <th className="system--users__component--table__content--td__chekbox"></th>
                        <th>Пользователь</th>
                        <th>Системная роль</th>
                        <th style={stateArrowDateEnter != 1 ? {backgroundColor: "rgba(20, 156, 206, 0.07)"} : {}}>
                            <div onClick={sortDatentry} className='system--users__component--table__content--th__datentry'>
                                <div>
                                    Дата последнего входа
                                </div>
                                {stateArrowDateEnter == 2 ?
                                    <div className='system--users__component--table__content--th__datentry--img'>
                                        <img src={ArrowDown}/>
                                    </div>
                                : stateArrowDateEnter == 3 ?
                                    <div className='system--users__component--table__content--th__datentry--img'>
                                        <img src={ArrowUp}/>
                                    </div>
                                : <></>
                                }
                            </div>
                        </th>
                        <th style={stateArrowDateUpdate != 1 ? {backgroundColor: "rgba(20, 156, 206, 0.07)"} : {}}>
                            <div onClick={sortDateUpdate}  className='system--users__component--table__content--th__datentry'>
                                <div>
                                    Дата обновления
                                </div>
                                {stateArrowDateUpdate == 2 ?
                                    <div className='system--users__component--table__content--th__datentry--img'>
                                        <img src={ArrowDown}/>
                                    </div>
                                    : stateArrowDateUpdate == 3 ?
                                        <div className='system--users__component--table__content--th__datentry--img'>
                                            <img src={ArrowUp}/>
                                        </div>
                                    : <></>
                                }
                            </div>
                        </th>
                        <th>
                            <div onClick={() => (setShowAllStatus(!showAllStatus))} className='system--users__component--table__content--th__status'>
                                <div  className='system--users__component--table__content--th__status--header'>
                                    {stateStatus != "Сбросить" && stateStatus ? stateStatus : "Статус"}
                                </div>
                                {showAllStatus ?
                                    <div className='system--users__component--table__content--td__status--choise'>
                                        <div className='system--users__component--widget__Edit--block__status--absolut'>
                                            {statusAll.map(xx => (
                                                <div onClick={() => (sortStatus(xx), setShowAllStatus(!showAllStatus))}
                                                     className='system--users__component--table__content--td__role--choise__one'>
                                                    {xx}
                                                </div>
                                            ))}
                                            <div onClick={() => (sortStatus("Сбросить"), setShowAllStatus(!showAllStatus))}
                                                 className='system--users__component--table__content--td__role--choise__one--clean'>
                                                Сбросить
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                        </th>
                    </tr>
                    {allUsers && allUsers?.map((record) => (
                        <tr className="system--users__component--table__content--tr">
                            <td className="system--users__component--table__content--td__chekbox">
                                <input type='checkbox' checked={record.chek}
                                       onClick={() => (changeChek(record.user.id, !record.chek))}/>
                            </td>
                            <td>
                                <div className="system--users__component--table__content--td__block--img">
                                    <div className="system--users__component--table__content--td__img">
                                        <img src={record.user.user.photo ? record.user.user.photo : PersonIcon}/>
                                    </div>
                                    <div className='system--users__component--table__content--td__block--name__email'>
                                        <div className='system--users__component--table__content--td__block--name'>
                                            {cropFIO(record.user.user.name)}
                                        </div>
                                        <div className='system--users__component--table__content--td__block--email'>
                                            {record.user.user.email}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td  className='system--users__component--table__content--td__block--role'>
                                <div className='system--users__component--table__content--td__role--block'>
                                    <div className='system--users__component--table__content--td__role'>
                                        <div className='system--users__component--table__content--td__role--name'>
                                            {record.user.systemRole.name}
                                        </div>
                                        <button
                                            onClick={() => (viewBlock("SystemRole-" + String(record?.user.id), "SystemGroupArrowSub" + String(record?.user.id), "SystemGroupArrowRight" + String(record?.user.id)))}
                                            className='add__edit--role__component--button__show--more'>
                                            <img src={ArrowRight} id={"SystemGroupArrowSub" + String(record?.user.id)}
                                                style={{display: 'none'}}/>
                                            <img src={ArrowSub} id={"SystemGroupArrowRight" + String(record?.user.id)}/>
                                        </button>
                                    </div>
                                    <div className='system--users__component--table__content--td__role--choise' style={{display: "none"}} id={"SystemRole-" + String(record?.user.id)}>
                                        <div className='system--users__component--widget__Edit--block__role--absolut' style={{background: "white"}}>
                                            {allRoles && allRoles.map((role) => (
                                                <div className='system--users__component--table__content--td__role--choise__one'
                                                     onClick={() => (handleRoleChange("SystemRole-" + String(record?.user.id), role.id, record?.user.id))}>
                                                    {role.roleName}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td style={stateArrowDateEnter != 1 ? {backgroundColor: "rgba(20, 156, 206, 0.07)"} : {}}>
                                <div className='system--users__component--table__content--td__date'>
                                    {dateToString(record.user.dateLastIn)}
                                </div>
                            </td>
                            <td style={stateArrowDateUpdate != 1 ? {backgroundColor: "rgba(20, 156, 206, 0.07)"} : {}}>
                                <div  className='system--users__component--table__content--td__date'>
                                    {dateToString(record.user.dateUpdate)}
                                </div>
                            </td>
                            <td>
                                <div className='system--users__component--table__content--td_status' style={{border: "1px solid " + getColor(record.user.status), color: getColor(record.user.status)}}>
                                    {record.user.status}
                                </div>
                            </td>
                        </tr>
                    ))}
                </table>
            </div>
        </div>
    );
};

export default SystemUsers;