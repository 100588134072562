import { Link, useNavigate } from "react-router-dom";
import "./NavigationPanel.css";
import { ISideBarProject } from "../../models/LeftMenuModels";
import { FC, useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import InfoPanel from "../LeftMenu/InfoPanel/InfoPanel";
import ArrowButton from "../buttons/ArrowButton/ArrowButton";
import { Context } from "../..";
import MonitorLogo from "../../assets/monitor.svg";
import MonitorLogoWhite from "../../assets/monitor_white.svg";
import ProjectLogo from "../../assets/projects_logo.svg";
import ProjectLogoWhite from "../../assets/projects_logo_white.svg";
import ArchiveLogo from "../../assets/arhive.svg";
import ArchiveLogoWhite from "../../assets/arhive_white.svg";
import TrashLogo from "../../assets/deleteElementGrey.svg";
import TrashLogoWhite from "../../assets/deleteElementWhite.svg";
import BoardComponent from "./BoardComponent";
import SubprojectComponent from "./SubprojectComponent";

export interface ISideBarItem {
    label: string;
    url: string;
    isHighlighted: boolean;
}

export type HighlightOptions = "projects" | "monitor" | "archive" | "trash";

export interface INavBarInfo {
    projectId?: number;
    subprojectId?: number;
    boardId?: number;
    openProjectIds?: number[];
}

const NavigationPanel: FC = () => {
    const navigate = useNavigate();
    const { sideBarProjects, toggleProject, navPanelHighlight, navBarInfo } =
        useContext(AppContext);
    const [projectListOpen, setProjectListOpen] = useState<boolean>(false);
    const { store } = useContext(Context);

    useEffect(() => {
        if (navPanelHighlight === "projects") setProjectListOpen(true);
        if (
            navBarInfo?.projectId ||
            navBarInfo?.subprojectId ||
            navBarInfo?.boardId
        )
            setProjectListOpen(true);
    }, [navPanelHighlight, navBarInfo]);

    return (
        <div className="nav-panel">
            <Link
                className={`nav-panel_item ${
                    navPanelHighlight === "projects"
                        ? "nav-panel_highlighted-row"
                        : ""
                } ${
                    navBarInfo.projectId
                        ? "nav-panel_light-highlighted-row"
                        : ""
                }`}
                to={"/projects"}
            >
                <div className="nav-panel_projects-container">
                    <div className="nav-panel_label-container">
                        <img
                            src={
                                navPanelHighlight === "projects"
                                    ? ProjectLogoWhite
                                    : ProjectLogo
                            }
                            alt="projects-logo"
                        />
                        <div className={`nav-panel_label`}>Проекты</div>
                    </div>
                    <div className="nav-panel_project-icons">
                        <InfoPanel
                            count={sideBarProjects?.length}
                            idKey="projectCount"
                        />
                        <ArrowButton
                            onClick={(e) => {
                                e.preventDefault();
                                setProjectListOpen(!projectListOpen);
                            }}
                            open={projectListOpen}
                            whiteArrow={navPanelHighlight === "projects"}
                        />
                    </div>
                </div>
            </Link>
            <div className="nav-panel_projects-list">
                {projectListOpen
                    ? (sideBarProjects as ISideBarProject[]).map((project) => (
                          <div key={project.id}>
                              <Link
                                  to={"/project/" + project.id}
                                  className={`nav-panel_project ${
                                      navBarInfo?.openProjectIds?.includes(
                                          project.id
                                      )
                                          ? "open"
                                          : ""
                                  } ${
                                      navBarInfo?.projectId === project.id
                                          ? "nav-panel_highlighted-row"
                                          : ""
                                  }`}
                                  onClick={() => {
                                    if (navBarInfo?.projectId === project.id) toggleProject(project.id)
                                  }}
                              >
                                  <div className="nav-panel_label">
                                      {project.name}
                                  </div>
                                  <ArrowButton
                                      onClick={(e) => {
                                          e.preventDefault();
                                          toggleProject(project.id);
                                      }}
                                      open={navBarInfo?.openProjectIds?.includes(
                                          project.id
                                      )}
                                      whiteArrow={
                                          navBarInfo?.projectId === project.id
                                      }
                                  />
                              </Link>
                              {navBarInfo?.openProjectIds?.includes(
                                  project.id
                              ) ? (
                                  <div>
                                      {project.boards
                                          .filter(
                                              (b) =>
                                                  store.showProxyBoards ||
                                                  !b.isProxyBoard
                                          )
                                          .map((board) => (
                                              <BoardComponent
                                                key={board.id}
                                                  board={board}
                                                  navBarInfo={navBarInfo}
                                              />
                                          ))}
                                      {project.subprojects?.map(
                                          (subproject) => (
                                              <SubprojectComponent
                                                    key={subproject.id}
                                                  subproject={subproject}
                                                  navBarInfo={navBarInfo}
                                              />
                                          )
                                      )}
                                  </div>
                              ) : null}
                          </div>
                      ))
                    : null}
            </div>
            <Link
                className={`nav-panel_item ${
                    navPanelHighlight === "monitor"
                        ? "nav-panel_highlighted-row"
                        : ""
                }`}
                to={"/monitor"}
            >
                <div className="nav-panel_label-container">
                    <img
                        src={
                            navPanelHighlight === "monitor"
                                ? MonitorLogoWhite
                                : MonitorLogo
                        }
                        alt="projects-logo"
                    />
                    <div className="nav-panel_label">Монитор пользователя</div>
                </div>
            </Link>
            <Link
                className={`nav-panel_item ${
                    navPanelHighlight === "archive"
                        ? "nav-panel_highlighted-row"
                        : ""
                }`}
                to={"/archive"}
            >
                <div className="nav-panel_label-container">
                    <img
                        src={
                            navPanelHighlight === "archive"
                                ? ArchiveLogoWhite
                                : ArchiveLogo
                        }
                        alt="archive-logo"
                    />
                    <div className="nav-panel_label">Архив</div>
                </div>
            </Link>
            <Link
                className={`nav-panel_item ${
                    navPanelHighlight === "trash"
                        ? "nav-panel_highlighted-row"
                        : ""
                }`}
                to={"/trash"}
            >
                <div className="nav-panel_label-container">
                    <img
                        src={
                            navPanelHighlight === "trash"
                                ? TrashLogoWhite
                                : TrashLogo
                        }
                        alt="trash-logo"
                    />
                    <div className="nav-panel_label">Корзина</div>
                </div>
            </Link>
        </div>
    );
};

export default NavigationPanel;
