import { FC, useContext, useEffect, useState } from "react";
import { ISideBarSubproject } from "../../models/LeftMenuModels";
import { Link, useNavigate } from "react-router-dom";
import SubprojectIcon from "../../assets/subproject.svg";
import SubprojectIconWhite from "../../assets/subproject_white.svg";
import { INavBarInfo } from "./NavigationPanel";
import { Context } from "../..";
import BoardComponent from "./BoardComponent";
import "./NavigationPanel.css";

interface ISubprojectComponentProps {
    subproject: ISideBarSubproject;
    navBarInfo: INavBarInfo;
}

const SubprojectComponent: FC<ISubprojectComponentProps> = ({
    subproject,
    navBarInfo,
}) => {
    const navigate = useNavigate();
    const [highlighted, setHighlighted] = useState<boolean>(false);
    const { store } = useContext(Context);

    useEffect(() => {
        setHighlighted(navBarInfo.subprojectId === subproject.id);
    }, [navBarInfo, subproject]);

    return (
        <div>
            <Link
                className={`nav-panel_subproject ${
                    highlighted ? "nav-panel_highlighted-row" : ""
                }`}
                to={"/project/" + subproject.id}
            >
                {highlighted ? (
                    // Сделано через 2 img, потому что так производительней
                    <img src={SubprojectIconWhite} alt="subproject-icon" />
                ) : (
                    <img src={SubprojectIcon} alt="subproject-icon" />
                )}
                <div className={`nav-panel_label`}>{subproject.name}</div>
            </Link>
            {subproject.boards
                .filter((b) => store.showProxyBoards || !b.isProxyBoard)
                .map((board) => (
                    <div className="nav-panel_subboard" key={board.id}>
                        <BoardComponent board={board} navBarInfo={navBarInfo} />
                    </div>
                ))}
        </div>
    );
};

export default SubprojectComponent;
