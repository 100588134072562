import React, {FC, useContext, useEffect, useState} from 'react';
import "./ProjectAddPersonRole.css"
import ExitCross from "../../../assets/cancelGreey.svg";
import useOutsideAlerter from "../../../customHooks/useOutsideAlert";
import ChooseItem from "../chooseUser/ChooseItem";
import SaveInputValue from "../../../assets/save_input_value.svg";
import BtnCancel from "../../buttons/CancelButton/CancelButton";
import {useUpdateEffect} from "primereact/hooks";
import {Context} from "../../../index";
import ProjectEditRoleBoard from "../ProjectEditRoleBoard/ProjectEditRoleBoard";
import {IExcludePermissionFunction} from "../../../models/IExcludePermissionFunction";

interface Item {
    name: string;
    code: string;
}


interface IProjectAddPersonRole {
    closeFunc: () => void;
    value: string | undefined,
    setValue: (e: any) => void,
    open: boolean,
    save: (e: any) => void,
    typeInput: string,
    selectedUser?: Item | null,
    setSelectedUser?: (e: any) => void,
    selectedRole?: Item | null,
    setSelectedRole?: (e: any) => void,
    persons?: Item[] | null,
    roles?: Item[] | null,
    roleEdit?: IExcludePermissionFunction | undefined
    projectId: number | null,
}


const ProjectAddPersonRole: FC<IProjectAddPersonRole> = ({closeFunc, value, setValue, open,
    save, typeInput, selectedUser, setSelectedUser,
    selectedRole, setSelectedRole, persons, roles, roleEdit, projectId}) => {
    const outsideAlerterRef: any = useOutsideAlerter(() => closeModal());
    const [selectedRoleStep, setSelectedRoleStep] = useState<Item | null>(null);
    const [selectedUserStep, setSelectedUserStep] = useState<Item | null>(null);
    const [modeCreate, setModeCreate] = useState<boolean>(false);
    const { store } = useContext(Context);

    function closeModal() {
        closeFunc();
    }

    function selectedRoleFunc() {

    }

    function getRoleFunction() {
        // console.log(selectedRoleStep);
        // const roleFunction = store.getDataToExcludePermissions(Number(selectedRoleStep?.code));
        // console.log(roleFunction);
    }

    useEffect(() => {
        setSelectedUser!(selectedUserStep);
    }, [selectedUserStep]);

    useEffect(() => {
        setSelectedRole!(selectedRoleStep);
    }, [selectedRoleStep]);

    return (
        <div className="project__add--person__role--modal">
            <div className="project__add--person__role--block" ref={outsideAlerterRef}>
                {modeCreate == false ?
                    <div>
                        <div className="project__add--person__role--block__header">
                            <div className="project__add--person__role--block__header--name">
                                Добавить пользователя
                            </div>
                            <div className="project__add--person__role--block__header--exit">
                                <button onClick={() => {closeModal()}}>
                                    <img src={ExitCross}/>
                                </button>
                            </div>
                        </div>
                        <div className="project__add--person__role--block__settings">
                            <div className="project__add--person__role--block__settings--name__item">
                                Пользователь
                            </div>
                            <div className="project__add--person__role--block__settings--item">
                                <ChooseItem selectedItem={selectedUser!} setSelectedItem={setSelectedUserStep!} placeholderName='Выберите пользователя' data={persons}/>
                            </div>
                            <div className="project__add--person__role--block__settings--name__item">
                                Роль
                            </div>
                            <div className="project__add--person__role--block__settings--item">
                                <ChooseItem selectedItem={selectedRoleStep!} setSelectedItem={setSelectedRoleStep!} placeholderName='Выберите роль' data={roles}/>
                            </div>
                        </div>
                        <div className="project__add--person__role--block__buttons">
                            <div className="project__add--person__role--block__buttons--exit">
                                <button onClick={closeFunc}>
                                    Отмена
                                </button>
                            </div>
                            <div className="project__add--person__role--block__buttons--add">
                                <button onClick={(e: any) => {save(e); setModeCreate(true);}}>
                                    Создать
                                </button>
                            </div>
                        </div>
                    </div>
                :
                    <div className="project__add--person__role--block__edit--role">
                        <ProjectEditRoleBoard personId={selectedUserStep} closeFunc={closeFunc} roleEdit={roleEdit} projectId={projectId}/>
                    </div>
                }
            </div>
        </div>
    );
};

export default ProjectAddPersonRole;