import { FC, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BoardIcon from "../../assets/board_frame.svg";
import BoardIconWhite from "../../assets/board_frame_white.svg";
import { ISideBarBoard } from "../../models/LeftMenuModels";
import { INavBarInfo } from "./NavigationPanel";
import "./NavigationPanel.css";

interface IBoardComponentProps {
    board: ISideBarBoard;
    navBarInfo: INavBarInfo;
}

const BoardComponent: FC<IBoardComponentProps> = ({ board, navBarInfo }) => {
    const navigate = useNavigate();

    const [highlighted, setHighlighted] = useState<boolean>(false);

    useEffect(() => {
        setHighlighted(navBarInfo.boardId === board.id);
    }, [navBarInfo, board]);

    return (
        <div>
            <Link
                className={`nav-panel_board ${
                    highlighted ? "nav-panel_highlighted-row" : ""
                }`}
                to={"/board/" + board.id}
            >
                <div className="nav-panel_label">
                    {board.name}
                </div>
                <img
                    src={highlighted ? BoardIconWhite : BoardIcon}
                    alt="board-icon"
                />
            </Link>
        </div>
    );
};

export default BoardComponent;
